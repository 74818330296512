import React from 'react';

import MainLayout from '../components/MainLayout';
import ToolRate from '../sections/ToolRate';

import api from '../helpers/api';

class ToolRatePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
      toolId: this.props.match.params.toolId,
      tool: {},
    };
  }

  componentDidMount() {
    this.getPage();
    this.getTool();
  }

  getPage = () => {
    api.getToolPage().then((page) => {
      this.setState({ page });
    });
  };

  getTool = () => {
    api.getTool(this.state.toolId).then((tool) => {
      this.setState({ tool });
    });
  };

  render() {
    const page = this.state.page || {};
    const tool = this.state.tool || {};

    return (
      <MainLayout page={page} headerType="empty" history={this.props.history}>
        <ToolRate history={this.props.history} tool={tool} page={page} />
      </MainLayout>
    );
  }
}

export default ToolRatePage;
