import React from 'react';
import { detect } from 'detect-browser';

class FlexNotSupported extends React.PureComponent {
  render() {
    const browser = detect();

    return (
      <div>
        <h1>Uw huidige browser wordt niet ondersteund.</h1>
        <p>&nbsp;</p>
        <h3>Uw huidige browser</h3>
        <p>Browser: {browser.name}</p>
        <p>Versie: {browser.version}</p>
        <p>OS: {browser.os}</p>
        <h2>&nbsp;</h2>
        <h3>Update uw browser</h3>
        <p>
          We raden je aan om altijd de nieuwste versie van een browser te gebruiken. Installeer daarom in een paar
          stappen de nieuwste versie van:
        </p>
        <ul>
          <li>
            <a
              href="https://www.google.nl/intl/nl/chrome/browser/"
              target="_blank"
              rel="noopener noreferrer"
              data-action="Hyperlink"
              data-upc-tooltip-type="none"
            >
              Google Chrome
            </a>
          </li>
          <li>
            <a
              href="https://www.mozilla.org/nl/firefox/new/"
              target="_blank"
              rel="noopener noreferrer"
              data-action="Hyperlink"
              data-upc-tooltip-type="none"
            >
              Firefox
            </a>
          </li>
          <li>
            <a
              href="https://www.microsoft.com/nl-nl/download/internet-explorer-11-for-windows-7-details.aspx"
              target="_blank"
              rel="noopener noreferrer"
              data-action="Hyperlink"
              data-upc-tooltip-type="none"
            >
              Internet Explorer
            </a>
            &nbsp;(vanaf Windows 7)
          </li>
          <li>
            <a
              href="https://www.apple.com/nl/downloads/"
              target="_blank"
              rel="noopener noreferrer"
              data-action="Hyperlink"
              data-upc-tooltip-type="none"
            >
              Safari
            </a>
            &nbsp;(vanaf OS X 10.9)
          </li>
        </ul>
      </div>
    );
  }
}

export default FlexNotSupported;
