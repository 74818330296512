import React from 'react';

import ExternHTML from '../components/ExternHTML';
import Sidebar from '../components/Sidebar';

class ExternContent extends React.PureComponent {
  render() {
    const html = this.props.html || '';
    const sidebar = this.props.sidebar || {};

    return (
      <section className={`section ${this.props.gray ? 'gray-background' : 'white-background'}`}>
        <div className="container">
          <div className="layout">
            <div className="content">
              {html && <ExternHTML html={html} />}
              {this.props.children}
            </div>

            <Sidebar sidebar={sidebar} />
          </div>
        </div>
      </section>
    );
  }
}

export default ExternContent;
