import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const baseURL = process.env.REACT_APP_ENV === 'development' ? 'http://localhost:3001' : 'https://api.flowley.nl';

const api = {
  cachedData: {},
  cacheLifetime: process.env.REACT_APP_ENV === 'development' ? 0 : 60 * 2000, // 2 minutes

  compare(a, b) {
    return b.index - a.index;
  },

  compareReversed(a, b) {
    return b.index - a.index;
  },

  getLocalItem(key, defaultValue) {
    try {
      return JSON.parse(localStorage.getItem(key)) || defaultValue;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);

      return defaultValue;
    }
  },

  setLocalItem(key, value) {
    try {
      return localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);

      return false;
    }
  },

  removeLocalItem(key) {
    try {
      return localStorage.removeItem(key);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);

      return false;
    }
  },

  getLocalUser() {
    return this.getLocalItem('user', {});
  },

  setLocalUser(user) {
    return this.setLocalItem('user', user);
  },

  getRatedTools() {
    return this.getLocalItem('ratedTools', {});
  },

  addRatedTool(toolId) {
    const ratedTools = this.getRatedTools();

    ratedTools[toolId] = true;

    return this.setLocalItem('ratedTools', ratedTools);
  },

  isToolRated(toolId) {
    const ratedTools = this.getRatedTools();

    return !!(ratedTools && ratedTools[toolId]);
  },

  getRatedPodcasts() {
    return this.getLocalItem('ratedPodcasts', {});
  },

  addRatedPodcasts(themeId) {
    const ratedPodcasts = this.getRatedPodcasts();

    ratedPodcasts[themeId] = true;

    return this.setLocalItem('ratedPodcasts', ratedPodcasts);
  },

  arePodcastsRated(themeId) {
    const ratedPodcasts = this.getRatedPodcasts();

    return !!(ratedPodcasts && ratedPodcasts[themeId]);
  },

  areCookiesAccepted() {
    return this.getLocalItem('cookiesAccepted', false);
  },

  setCookiesAccepted() {
    return this.setLocalItem('cookiesAccepted', true);
  },

  setCookiesNotAccepted() {
    return this.setLocalItem('cookiesAccepted', false);
  },

  isPartnerModalShown() {
    return this.getLocalItem('partnerModal', false);
  },

  setPartnerModalShown() {
    return this.setLocalItem('partnerModal', true);
  },

  isLoggedIn() {
    const user = this.getLocalUser();

    return user && Object.keys(user).length > 0;
  },

  async facebookLogin() {
    const result = await firebase.auth().signInWithPopup(facebookAuthProvider);

    if (!result || !result.user || Object.keys(result.user) < 1) {
      throw new Error('Facebook gaf geen resultaten terug.');
    }

    return result.user;
  },

  async twitterLogin() {
    const result = await firebase.auth().signInWithPopup(twitterAuthProvider);

    if (!result || !result.user || Object.keys(result.user) < 1) {
      throw new Error('Twitter gaf geen resultaten terug.');
    }

    return result.user;
  },

  async googleLogin() {
    const result = await firebase.auth().signInWithPopup(googleAuthProvider);

    if (!result || !result.user || Object.keys(result.user) < 1) {
      throw new Error('Google gaf geen resultaten terug.');
    }

    return result.user;
  },

  async logout() {
    this.removeLocalItem('user');
  },

  async login(user) {
    this.setLocalUser(user);

    return this.post('user', user, {});
  },

  async getTool(toolId) {
    return this.get(`tools/${toolId}`, {});
  },

  async getArticle(articleId) {
    return this.get(`articles/${articleId}`, {}, this.cacheLifetime);
  },

  async getTheme(themeId) {
    return this.get(`themes/${themeId}`, {}, this.cacheLifetime);
  },

  async getThemeArticles(themeId) {
    const articles = await this.get(`themes/${themeId}/articles`, []);
    articles.sort(this.compareReversed);
    return articles;
  },

  async getThemeTools(themeId) {
    const tools = await this.get(`themes/${themeId}/tools`, []);
    tools.sort(this.compareReversed);
    return tools;
  },

  async getThemeQuestions(themeId) {
    return this.get(`themes/${themeId}/questions`, []);
  },

  async getThemePodcasts(themeId) {
    return this.get(`themes/${themeId}/podcasts`, []);
  },

  async getThemeNextSteps(themeId) {
    return this.get(`themes/${themeId}/next-steps`, []);
  },

  async getThemes() {
    const themes = await this.get('themes', [], this.cacheLifetime);
    themes.sort(this.compareReversed);
    return themes;
  },

  async getQuestion(questionId) {
    return this.get(`questions/${questionId}`, {});
  },

  async getQuestionAnswers(questionId) {
    return this.get(`questions/${questionId}/answers`, []);
  },

  async getQuestionFollowers(questionId) {
    return this.get(`questions/${questionId}/followers`, []);
  },

  async getQuestions() {
    return this.get('questions', []);
  },

  async getTools() {
    const tools = await this.get('tools', [], this.cacheLifetime);
    tools.sort(this.compareReversed);
    return tools;
  },

  async getArticles() {
    const articles = await this.get('articles', [], this.cacheLifetime);
    articles.sort(this.compareReversed);
    return articles;
  },

  async getModal(modalId) {
    return this.get(`modals/${modalId}`, {}, this.cacheLifetime);
  },

  async getPartnerModal() {
    return this.getModal('partner');
  },

  async getChooserModal() {
    return this.getModal('chooser');
  },

  async getPages() {
    return this.get('pages', [], this.cacheLifetime);
  },

  async getPage(pageId) {
    return this.get(`pages/${pageId}`, {}, this.cacheLifetime);
  },

  async getAboutUsPage() {
    return this.getPage('about-us');
  },

  async getArticlePage() {
    return this.getPage('article');
  },

  async getArticlesPage() {
    return this.getPage('articles');
  },

  async getAskQuestionPage() {
    return this.getPage('ask-question');
  },

  async getCoachingPage() {
    return this.getPage('coaching');
  },

  async getCoachPage() {
    return this.getPage('coach');
  },

  async getContactPage() {
    return this.getPage('contact');
  },

  async getHomePage() {
    return this.getPage('home');
  },

  async getPageNotFoundPage() {
    return this.getPage('page-not-found');
  },

  async getQuestionPage() {
    return this.getPage('question');
  },

  async getQuestionsPage() {
    return this.getPage('questions');
  },

  async getTermsAndConditionsPage() {
    return this.getPage('terms-and-conditions');
  },

  async getPrivacyPolicyPage() {
    return this.getPage('privacy-policy');
  },

  async getThemePage() {
    return this.getPage('theme');
  },

  async getToolPage() {
    return this.getPage('tool');
  },

  async getToolsPage() {
    return this.getPage('tools');
  },

  async getWriteAnswerPage() {
    return this.getPage('write-answer');
  },

  async getComponent(componentId) {
    return this.get(`components/${componentId}`, {}, this.cacheLifetime);
  },

  async getMoreAboutComponent() {
    const moreAbout = await this.getComponent('more-about');
    if (moreAbout && moreAbout.blocks && moreAbout.blocks.length) {
      moreAbout.blocks.sort(this.compare);
    }
    return moreAbout;
  },

  async getPodcasts() {
    return this.get('podcasts', [], this.cacheLifetime);
  },

  async getPodcast(podcastId) {
    return this.get(`podcasts/${podcastId}`, {}, this.cacheLifetime);
  },

  async getCoach(coachId) {
    return this.get(`coaches/${coachId}`, {}, this.cacheLifetime);
  },

  async addQuestion(questionData) {
    const user = this.getLocalUser();

    const newQuestionData = {
      ...questionData,
      userId: user.uid,
    };

    const questionResult = await this.post('question', newQuestionData, {});

    const followerData = { questionId: questionResult.questionId };

    const followerResult = await this.toggleFollower(followerData);

    return {
      questionResult,
      followerResult,
    };
  },

  async addAnswer(data) {
    const user = this.getLocalUser();

    const newData = {
      ...data,
      userId: user.uid,
    };

    return this.post('answer', newData, {});
  },

  async toggleFollower(data) {
    const user = this.getLocalUser();

    const newData = {
      ...data,
      userId: user.uid,
    };

    return this.post('follower', newData, {});
  },

  async addEmailSubscription(data) {
    const user = this.getLocalUser();

    const newData = { ...data };

    if (user) {
      newData.userId = user.uid;
    }

    return this.post('subscribe', newData, {});
  },

  async addRating(data) {
    const user = this.getLocalUser();

    const newData = {
      ...data,
      userId: user.uid,
    };

    return this.post('rate', newData, {});
  },

  async formSubmit(emailAddress, message) {
    return this.post(
      'form',
      {
        emailAddress,
        message,
      },
      false,
    );
  },

  async post(path, data, defaultValue) {
    const params = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return this.apiCall(path, params, defaultValue);
  },

  async get(path, defaultValue, cacheLifetime = 0) {
    if (cacheLifetime && this.cachedData[path] && this.cachedData[path].result && this.cachedData[path].lastUsed) {
      const lastUsed = this.cachedData[path].lastUsed || new Date();
      const checkTime = new Date(lastUsed.getTime() + cacheLifetime).getTime();
      const currentTime = new Date().getTime();

      if (checkTime > currentTime) {
        return this.cachedData[path].result;
      }
    }

    const result = await this.apiCall(path, { method: 'GET' }, defaultValue);

    this.cachedData[path] = {
      lastUsed: new Date(),
      result,
    };

    return result;
  },

  async apiCall(path, params, defaultValue) {
    let entity = 'default';
    if (process.env.REACT_APP_ENTITY_ID) {
      entity = process.env.REACT_APP_ENTITY_ID;
    } else if (window.location.host.split('.').length >= 3) {
      entity = window.location.host.split('.')[0].replace('www', 'default');
    }
    try {
      const response = await fetch(`${baseURL}/${path}?entity=${entity}`, params);
      if (!response) {
        return defaultValue;
      }
      return await response.json();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
      return defaultValue;
    }
  },
};

export default api;
