import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

class PodcastList extends React.PureComponent {
  render() {
    const className = this.props.className || '';
    const backgroundClass = this.props.gray ? 'gray-background' : 'white-background';

    if (this.props.noSection) {
      return <div className={`${backgroundClass} ${className}`}>{this.renderContent()}</div>;
    }

    return <section className={`section ${backgroundClass} ${className}`}>{this.renderContent()}</section>;
  }

  renderContent() {
    const podcasts = this.props.podcasts || [];
    const theme = this.props.theme || {};

    const containerClass = this.props.noSection ? '' : 'container';

    return (
      <div className={containerClass}>
        {theme.podcastImageURL && (
          <div className="layout">
            <div className="full">
              <div
                className="header-image-small radius"
                style={{ backgroundImage: `url('${theme.podcastImageURL}')` }}
              />
            </div>
          </div>
        )}

        <div className="layout padding-xsmall">
          {podcasts.map((podcast) => (
            <div key={`podcast-${podcast.id}`} className="column-2">
              {podcast.imageURL && (
                <div className="thumbnail-image radius" style={{ backgroundImage: `url('${podcast.imageURL}')` }} />
              )}

              {podcast.title && <h3>{podcast.title}</h3>}

              {podcast.description && <p>{podcast.description}</p>}

              <ReactAudioPlayer
                id={`podcast-${podcast.id}`}
                style={{ width: '100%' }}
                src={podcast.audioURL}
                controls
              />
            </div>
          ))}
        </div>

        {this.props.children}
      </div>
    );
  }
}

export default PodcastList;
