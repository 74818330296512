import React from 'react';

class FormFail extends React.PureComponent {
  render() {
    if (!this.props.visible) {
      return null;
    }

    const text = this.props.text || 'Er is iets fout gegaan';
    const className = this.props.className || '';

    return <div className={`form-fail ${className}`}>❌ {text}</div>;
  }
}

export default FormFail;
