import React from 'react';

class Pill extends React.PureComponent {
  render() {
    const className = this.props.className || '';

    let colorClass = '';
    let imageURL = '';
    if (this.props.image === 'blue') {
      colorClass = 'secondary-color-text';
      imageURL = '/images/intensity.svg';
    } else if (this.props.image === 'red') {
      colorClass = 'primary-color-text';
      imageURL = '/images/rating.svg';
    }

    return (
      <div className={`thumbnail-info radius-small ${colorClass} ${className}`.trim()}>
        <img className="margin-right-small" src={imageURL} alt="" />
        {this.props.text}
      </div>
    );
  }
}

export default Pill;
