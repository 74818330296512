import React from 'react';

import FormSubmit from '../components/FormSubmit';
import FormDone from '../components/FormDone';
import FormFail from '../components/FormFail';

import api from '../helpers/api';
import main from '../helpers/main';

class KeepInTouch extends React.PureComponent {
  state = {
    submitting: false,
    formValues: {
      email: null,
    },
  };

  handleChange = (event) => {
    const formValues = { ...this.state.formValues };
    formValues[event.target.name] = event.target.value;

    this.setState({ formValues });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.isFormValid()) {
      return;
    }

    const { formValues } = this.state;

    this.setState({ submitting: true });

    api.addEmailSubscription(formValues).then((result) => {
      this.setState({ submitting: false });

      if (result) {
        main.reload();
      }
    });
  };

  isFormValid = () => {
    const { email } = this.state.formValues;

    return email && email.length > 0 && this.validateEmail(email);
  };

  validateEmail = (email) => {
    const re = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    return (email || '').match(re);
  };

  render() {
    const submitDisabled = !this.isFormValid() || this.state.submitting;

    return (
      <section className={`section ${this.props.gray ? 'gray-background' : 'white-background'}`}>
        <div className="container">
          <div className="layout">
            <div className="full">
              <h2>Blijf op de hoogte</h2>

              <p>
                Meld je aan voor onze nieuwsbrief om op de hoogte te blijven van alle ontwikkelingen en de nieuwste
                thema's op Flowley.
              </p>

              <div className="w-form">
                <form onSubmit={this.handleSubmit} className="form w-clearfix">
                  <div className="flex-child">
                    <input
                      type="text"
                      className={`text-field newsletter radius _15-right w-input ${
                        this.props.gray ? 'white-background' : 'gray-background'
                      }`}
                      maxLength="256"
                      name="email"
                      data-name="email"
                      placeholder="Vul je e-mailadres in"
                      id="email"
                      onChange={this.handleChange}
                      required
                    />

                    <FormSubmit
                      submitting={this.state.submitting}
                      disabled={submitDisabled}
                      className="large radius primary-color"
                      text="Schrijf je in"
                    />
                  </div>
                </form>

                <FormDone text="Je antwoord is verstuurd" />

                <FormFail text="Er is iets fout gegaan tijdens het versturen van je antwoord" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default KeepInTouch;
