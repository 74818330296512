import React from 'react';
import { Link } from 'react-router-dom';

import api from '../helpers/api';
import main from '../helpers/main';

class Nav extends React.PureComponent {
  state = {
    themes: [],
  };

  componentDidMount() {
    this.getThemes();
  }

  getThemes = () => {
    api.getThemes().then((themes) => {
      this.setState({ themes });
    });
  };

  logout = () => {
    api.logout().then(() => {
      // Pass.
    });

    main.reload();
  };

  render() {
    const themes = this.state.themes || [];
    const user = api.getLocalUser();

    return (
      <nav>
        <div className="navbar">
          <div className="shadow" />

          <div className="container-large" id="nav-container">
            <div className="padding" />

            <div className="flex-parent">
              <div className="nav-left">
                <Link to="/home" className="logo w-inline-block min-z-index">
                  <img src="/images/flowley-logo.svg" alt="" />
                </Link>

                <div className="navbar-button _15-left" data-ix="dropdown">
                  <div className="dropdown-theme-label">Thema&#x27;s</div>

                  <img src="/images/icon-arrow-down.svg" className="icon-arrow-down" alt="" />

                  <div className="dropdown-theme radius" />

                  <div className="dropdown-menu-parent">
                    <div className="dropdown-menu radius">
                      {themes
                        .filter((theme) => theme.id !== 'placeholder')
                        .map((theme) => (
                          <Link
                            key={`theme-${theme.id}`}
                            to={`/thema/${theme.id}`}
                            className="dropdown-menu-link w-inline-block"
                          >
                            {theme.title}
                          </Link>
                        ))}

                      <div className="separator" />

                      <Link to={`/contact?subject=theme`} className="dropdown-menu-link w-inline-block">
                        Mis je een thema?
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="nav-center no-mobile z-index">
                <a href="https://open.spotify.com/show/2q7Uy4hku9Jh0s8M91pger" rel="noreferrer" target="_blank">
                  <img
                    alt=""
                    src="https://firebasestorage.googleapis.com/v0/b/flowley-uu-f3377.appspot.com/o/spotify-podcast-badge-wht-grn-165x40.svg?alt=media&token=eca01126-a4af-4781-94c9-e1895aee85df"
                  />
                </a>
              </div>

              <div className="nav-right">
                <Link to="/over-ons" className="navbar-button _15-right w-inline-block">
                  Over ons
                </Link>

                <div className="hide-mobile">
                  {api.isLoggedIn() ? (
                    <div className="button radius gray-background" data-ix="dropdown">
                      <div className="gray-color-text">{user.displayName}</div>

                      <img src="/images/icon-arrow-down.svg" className="icon-arrow-down" alt="" />

                      <div className="dropdown-theme radius" />

                      <div className="dropdown-menu-parent align-right hide-desktop">
                        <div className="dropdown-menu radius">
                          <button
                            onMouseDown={(e) => e.preventDefault()}
                            type="button"
                            onClick={this.logout}
                            className="dropdown-menu-link w-inline-block pointer"
                          >
                            Uitloggen
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="button radius primary-color hide-responsive w-inline-block pointer"
                      data-ix="open-modal-login"
                    >
                      Aanmelden
                    </div>
                  )}
                </div>

                <div
                  className="button radius gray-background responsive-menu w-inline-block pointer gray-color-text"
                  data-ix="open-responsive-menu"
                >
                  Menu
                  <img src="/images/icon-arrow-down.svg" className="icon-arrow-down" alt="" />
                </div>
              </div>
            </div>

            <div className="nav-center margin-top yes-mobile">
              <a href="https://open.spotify.com/show/2q7Uy4hku9Jh0s8M91pger" rel="noreferrer" target="_blank">
                <img
                  alt=""
                  src="https://firebasestorage.googleapis.com/v0/b/flowley-uu-f3377.appspot.com/o/spotify-podcast-badge-wht-grn-165x40.svg?alt=media&token=eca01126-a4af-4781-94c9-e1895aee85df"
                />
              </a>
            </div>

            <div className="padding" />

            <div className="hide-desktop">
              <div className="responsive-drop-down hide-responsive">
                <div className="layout">
                  <div className="column-2 flex">
                    <h3>Thema&#x27;s</h3>

                    {themes
                      .filter((theme) => theme.id !== 'placeholder')
                      .map((theme) => (
                        <Link
                          key={`mobile-theme-${theme.id}`}
                          to={`/thema/${theme.id}`}
                          className="footer-link dark-color-text w-inline-block"
                        >
                          {theme.title}
                        </Link>
                      ))}

                    <div className="separator" />

                    <Link to={`/contact?subject=theme`} className="footer-link dark-color-text w-inline-block">
                      Mis je een thema?
                    </Link>
                  </div>

                  <div className="column-2 flex">
                    <div className="hide-desktop">
                      {!api.isLoggedIn() ? (
                        <div
                          className="button radius primary-color _15-bottom w-inline-block pointer"
                          data-ix="open-modal-login"
                        >
                          Aanmelden voor Flowley
                        </div>
                      ) : null}
                    </div>

                    <div className="hide-desktop">
                      {api.isLoggedIn() ? (
                        <button
                          onMouseDown={(e) => e.preventDefault()}
                          type="button"
                          onClick={this.logout}
                          className="button radius _15-bottom w-inline-block pointer"
                        >
                          Uitloggen
                        </button>
                      ) : null}
                    </div>

                    <Link to="/over-ons" className="footer-link dark-color-text w-inline-block">
                      Over ons
                    </Link>

                    <Link to="/algemene-voorwaarden" className="footer-link dark-color-text w-inline-block">
                      Algemene voorwaarden
                    </Link>

                    <Link to="/privacy-voorwaarden" className="footer-link dark-color-text w-inline-block">
                      Privacy voorwaarden
                    </Link>

                    <Link to="/contact" className="footer-link dark-color-text w-inline-block">
                      Contact
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Nav;
