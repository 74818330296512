import React from 'react';

import MainLayout from '../components/MainLayout';
import ArticleList from '../sections/ArticleList';
import KeepInTouch from '../sections/KeepInTouch';

import api from '../helpers/api';

class ArticlesPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
      articles: [],
    };
  }

  componentDidMount() {
    this.getPage();
    this.getArticles();
  }

  getPage = () => {
    api.getArticlesPage().then((page) => {
      this.setState({ page });
    });
  };

  getArticles = () => {
    api.getArticles().then((articles) => {
      this.setState({ articles });
    });
  };

  render() {
    const page = this.state.page || {};
    const articles = this.state.articles || [];

    return (
      <MainLayout page={page} headerType="collection" history={this.props.history}>
        <ArticleList articles={articles} className="padding-xsmall" />

        <KeepInTouch gray />
      </MainLayout>
    );
  }
}

export default ArticlesPage;
