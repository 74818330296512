import React from 'react';
import { Link } from 'react-router-dom';

import SidebarBlock from './SidebarBlock';
import Pill from './Pill';

class SidebarTools extends React.PureComponent {
  render() {
    const tools = this.props.tools || [];

    return (
      <SidebarBlock label="Oefeningen">
        {tools.map((tool) => (
          <Link key={`tool-${tool.id}`} to={`/oefening/${tool.id}`}>
            <div className="sidebar-item">
              <div className="oefening-image radius-small" style={{ backgroundImage: `url('${tool.imageURL}')` }}>
                <div className="challenge-info small">
                  <h4 className="title-small">{tool.title}</h4>
                </div>

                <div className="dark-overlay" />

                <Pill text={tool.intensity} image="blue" className="radius-xsmall left" />

                <Pill text={tool.averageRating} image="red" className="radius-xsmall" />
              </div>
            </div>
          </Link>
        ))}
      </SidebarBlock>
    );
  }
}

export default SidebarTools;
