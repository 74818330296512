import React from 'react';

import ExternHTML from '../components/ExternHTML';
import Url from '../components/Url';
import api from '../helpers/api';

class MoreAbout extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      component: {},
    };
  }

  componentDidMount() {
    this.getComponent();
  }

  getComponent = () => {
    api.getMoreAboutComponent().then((component) => {
      this.setState({ component });
    });
  };

  render() {
    const component = this.state.component || {};
    const blocks = component.blocks || [];

    return (
      <section id="more-about" className={`section ${this.props.gray ? 'gray-background' : 'white-background'}`}>
        <div className="container">
          <div className="layout">
            <div className="full centered">
              <h2>{component.title}</h2>
              <p className="centered-paragraph">{component.description}</p>
            </div>
          </div>
          <div className="layout">
            {blocks.map((block) => (
              <div key={`more-info-${block.id}`} className="column-2">
                <div className={`card radius ${this.props.gray ? 'white-background' : 'gray-background'}`}>
                  {block.title && <h3 className="card-title">{block.title}</h3>}
                  {block.description && <p>{block.description}</p>}
                  {block.html && <ExternHTML html={block.html} />}
                  {block.link && (
                    <Url url={block.link} className="text-button w-inline-block">
                      {block.linkName}
                      <div className="text-button-line primary-color" />
                    </Url>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default MoreAbout;
