import React from 'react';

import MainLayout from '../components/MainLayout';
import WriteAnswer from '../sections/WriteAnswer';

import api from '../helpers/api';

class WriteAnswerPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
      questionId: this.props.match.params.questionId,
    };
  }

  componentDidMount() {
    // Redirect visitor that is not logged in to the home page.
    if (!api.isLoggedIn()) {
      this.props.history.push('/');

      return;
    }

    this.getPage();
    this.getQuestion();
  }

  getPage = () => {
    api.getWriteAnswerPage().then((page) => {
      this.setState({ page });
    });
  };

  getQuestion = () => {
    api.getQuestion(this.state.questionId).then((question) => {
      this.setState({ question });
    });
  };

  render() {
    const page = this.state.page || {};
    const question = this.state.question || {};

    return (
      <MainLayout page={page} headerType="back" history={this.props.history}>
        <WriteAnswer question={question} history={this.props.history} />
      </MainLayout>
    );
  }
}

export default WriteAnswerPage;
