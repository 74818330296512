import React from 'react';

import MainLayout from '../components/MainLayout';
import ExternContent from '../sections/ExternContent';

import api from '../helpers/api';

class PrivacyPolicyPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
    };
  }

  componentDidMount() {
    this.getPage();
  }

  getPage = () => {
    api.getPrivacyPolicyPage().then((page) => {
      this.setState({ page });
    });
  };

  render() {
    const page = this.state.page || {};

    return (
      <MainLayout page={page} headerType="standard" history={this.props.history}>
        <ExternContent html={page.contentHTML} sidebar={page.sidebar} />
      </MainLayout>
    );
  }
}

export default PrivacyPolicyPage;
