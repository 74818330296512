import React from 'react';
import { Link } from 'react-router-dom';

import ExternHTML from '../components/ExternHTML';
import Sidebar from '../components/Sidebar';
import SidebarBlock from '../components/SidebarBlock';
import SidebarTools from '../components/SidebarTools';

class Article extends React.PureComponent {
  render() {
    const article = this.props.article || {};
    const theme = this.props.theme || {};
    const tools = this.props.tools || [];

    return (
      <section className="section">
        <div className="container">
          <div className="layout">
            <div className="content">
              <ExternHTML html={article.contentHTML} />
            </div>

            <Sidebar showShare>
              <SidebarBlock label="Beoordeling">
                <div className="sidebar-stats-parent">
                  <img src="/images/rating-icon.svg" alt="" />

                  <div className="sidebar-stats primary-color-text">{article.averageRating}</div>
                </div>
              </SidebarBlock>

              <SidebarTools tools={tools} />

              <SidebarBlock label="Thema">
                <Link to={`/thema/${theme.id}`} className="theme-button sidebar-theme radius-small w-inline-block">
                  {theme.title}
                </Link>
              </SidebarBlock>
            </Sidebar>
          </div>
        </div>
      </section>
    );
  }
}

export default Article;
