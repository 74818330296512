import React from 'react';

import SidebarBlock from './SidebarBlock';

class SidebarTools extends React.PureComponent {
  render() {
    const steps = this.props.steps || [];

    return (
      <SidebarBlock>
        <div className={`sidebar-step ${this.props.atBegin ? 'active' : ''} radius-small w-inline-block`}>
          <span className={`step-nr ${this.props.atBegin ? 'active-step-nr' : ''}`}>00</span> Overzicht
        </div>

        {steps.map((step, index) => {
          const number = index + 1;
          return (
            <div
              className={`sidebar-step ${step.id === this.props.currentId ? 'active' : ''} radius-small w-inline-block`}
              key={`sidebar-step-${step.id}`}
            >
              <span className={`step-nr ${step.id === this.props.currentId ? 'active-step-nr' : ''}`}>{`${
                number < 10 ? '0' : ''
              }${number}`}</span>{' '}
              {step.title}
            </div>
          );
        })}

        <div className={`sidebar-step ${this.props.atEnd ? 'active' : ''} radius-small w-inline-block`}>
          <span className={`step-nr ${this.props.atEnd ? 'active-step-nr' : ''}`}>
            {steps.length + 1 < 10 ? '0' : ''}
            {steps.length + 1}
          </span>{' '}
          Voltooien
        </div>
      </SidebarBlock>
    );
  }
}

export default SidebarTools;
