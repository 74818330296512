import React from 'react';
import { Link } from 'react-router-dom';

import Pill from '../components/Pill';

class ArticleList extends React.PureComponent {
  render() {
    const className = this.props.className || '';
    const backgroundClass = this.props.gray ? 'gray-background' : 'white-background';

    if (this.props.noSection) {
      return <div className={`${backgroundClass} ${className}`}>{this.renderContent()}</div>;
    }

    return <section className={`section ${backgroundClass} ${className}`}>{this.renderContent()}</section>;
  }

  renderContent() {
    let articles = this.props.articles || [];

    if (this.props.highlighted && articles.length > 2) {
      articles = articles.slice(0, 2);
    }

    const containerClass = this.props.noSection ? '' : 'container';

    return (
      <div className={containerClass}>
        {this.props.highlighted ? (
          <div className="layout">
            <div className="full">
              <div className="flex-parent">
                <div className="flex-left">
                  <h3 className="section-title">Laatste insights</h3>
                </div>

                <div className="flex-right">
                  <Link to="/artikelen" className="text-button w-inline-block">
                    Zie alle insights
                    <div className="text-button-line" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="layout">
          {articles.map((article) => {
            const theme = article.theme || {};

            return (
              <div key={`article-${article.id}`} className="column-2">
                <Link to={`/artikel/${article.id}`}>
                  <div className="thumbnail-image radius" style={{ backgroundImage: `url('${article.imageURL}')` }}>
                    <Pill text={article.averageRating} image="red" />
                  </div>
                </Link>

                <h3>{article.title}</h3>

                <p>{article.description}</p>

                {this.props.hideThemes ? null : (
                  <Link to={`/thema/${theme.id}`} className="theme-button radius w-inline-block">
                    {theme.title}
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ArticleList;
