import React from 'react';

import MainLayout from '../components/MainLayout';

import api from '../helpers/api';

class PageNotFound extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
    };
  }

  componentDidMount() {
    this.getPage();
  }

  getPage = () => {
    api.getPageNotFoundPage().then((page) => {
      this.setState({ page });
    });
  };

  render() {
    const page = this.state.page || {};

    return <MainLayout page={page} headerType="collection" history={this.props.history} />;
  }
}

export default PageNotFound;
