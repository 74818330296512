import React from 'react';

import api from '../helpers/api';

class FacebookButton extends React.PureComponent {
  login = async () => {
    try {
      const user = await api.facebookLogin();

      if (this.props.onResult) {
        this.props.onResult(user);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);

      if (this.props.onError) {
        this.props.onError(error);
      }
    }
  };

  render() {
    return (
      <button
        onMouseDown={(e) => e.preventDefault()}
        type="button"
        onClick={this.login}
        className="button social-button radius fb-color w-inline-block full-width pointer"
      >
        Doorgaan met Facebook
      </button>
    );
  }
}

export default FacebookButton;
