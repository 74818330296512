import React from 'react';

import api from '../helpers/api';
import webflow from '../helpers/webflow';
import main from '../helpers/main';

class Header extends React.PureComponent {
  askQuestion = () => {
    if (!api.isLoggedIn()) {
      webflow.showLoginModal();

      return;
    }

    this.props.history.push('/stel-vraag');
  };

  back = () => {
    this.props.history.goBack();
  };

  scrollToMoreAbout = () => {
    main.scrollSmoothTo(document.getElementById('more-about'));
  };

  scrollToThemeList = () => {
    main.scrollSmoothTo(document.getElementById('theme-list'));
  };

  openThemeToolsPage = () => {
    const theme = this.props.theme || {};
    this.props.history.push(`/thema/${theme.id}#oefeningen`);
  };

  render() {
    const page = this.props.page || {};

    if (this.props.type === 'standard') {
      return (
        <header className="header">
          <div className="container">
            <div className="layout">
              <div className="full">
                <div className="label">{page.label}</div>

                <h1 className="page-title">{page.title}</h1>
              </div>
            </div>
          </div>
        </header>
      );
    }
    if (this.props.type === 'image') {
      return (
        <header className="header">
          <div className="container">
            <div className="layout">
              <div className="full">
                <div className="label">{page.label}</div>

                <h1 className="page-title">{page.title}</h1>

                <div className="header-image radius" style={{ backgroundImage: `url('${page.imageURL}')` }} />
              </div>
            </div>
          </div>
        </header>
      );
    }
    if (this.props.type === 'collection') {
      return (
        <header className="header">
          <div className="container">
            <div className="layout">
              <div className="full">
                <h1 className="page-title">{page.title}</h1>

                <p className="description">{page.description}</p>
              </div>
            </div>
          </div>
        </header>
      );
    }
    if (this.props.type === 'detail') {
      const article = this.props.article || {};
      return (
        <header className="header">
          <div className="container">
            <div className="layout">
              <div className="full">
                <button
                  onMouseDown={(e) => e.preventDefault()}
                  type="button"
                  onClick={this.back}
                  className="text-button w-inline-block pointer"
                >
                  Ga terug
                  <div className="text-button-line" />
                </button>

                <h1 className="page-title">{article.title}</h1>
              </div>
            </div>
          </div>

          <div className="header-image" style={{ backgroundImage: `url('${article.imageURL}')` }} />
        </header>
      );
    }
    if (this.props.type === 'back') {
      return (
        <header className="header">
          <div className="container">
            <div className="layout">
              <div className="full">
                <button
                  onMouseDown={(e) => e.preventDefault()}
                  type="button"
                  onClick={this.back}
                  className="text-button w-inline-block pointer"
                >
                  Ga terug
                  <div className="text-button-line" />
                </button>

                <h1 className="page-title">{page.title}</h1>
              </div>
            </div>
          </div>
        </header>
      );
    }
    if (this.props.type === 'back-image') {
      const tool = this.props.tool || {};
      return (
        <header className="header">
          <div className="container">
            <div className="layout">
              <div className="full">
                <button
                  onMouseDown={(e) => e.preventDefault()}
                  type="button"
                  onClick={this.back}
                  className="text-button w-inline-block pointer"
                >
                  Ga terug
                  <div className="text-button-line" />
                </button>

                <h1 className="page-title">{tool.title}</h1>

                <div className="header-image radius" style={{ backgroundImage: `url('${tool.imageURL}')` }} />
              </div>
            </div>
          </div>
        </header>
      );
    }
    if (this.props.type === 'tool-overview') {
      const tool = this.props.tool || {};
      return (
        <header className="header">
          <div className="container">
            <div className="layout">
              <div className="full">
                <button
                  onMouseDown={(e) => e.preventDefault()}
                  type="button"
                  onClick={this.openThemeToolsPage}
                  className="text-button w-inline-block pointer"
                >
                  Ga terug
                  <div className="text-button-line" />
                </button>

                <h1 className="page-title">{tool.title}</h1>

                <div className="header-image radius" style={{ backgroundImage: `url('${tool.imageURL}')` }} />
              </div>
            </div>
          </div>
        </header>
      );
    }
    if (this.props.type === 'empty') {
      return <header />;
    }
    if (this.props.type === 'home') {
      return (
        <header className="header">
          <div className="container">
            <div className="layout">
              <div className="full centered">
                <h1 className="page-title centered">{page.title}</h1>

                <p className="description max-width">{page.description}</p>

                <div className="header-buttons">
                  <button
                    onMouseDown={(e) => e.preventDefault()}
                    type="button"
                    onClick={this.scrollToThemeList}
                    className="text-button w-inline-block pointer"
                  >
                    Ga direct aan de slag
                    <div className="text-button-line primary-color" />
                  </button>

                  <div className="in-between-buttons">of</div>

                  <button
                    onMouseDown={(e) => e.preventDefault()}
                    type="button"
                    onClick={this.scrollToMoreAbout}
                    className="text-button w-inline-block pointer"
                  >
                    bekijk hoe het werkt
                    <div className="text-button-line" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
      );
    }
    if (this.props.type === 'questions') {
      return (
        <header className="header">
          <div className="container">
            <div className="layout">
              <div className="full">
                <h1 className="page-title">{page.title}</h1>

                <p className="description">{page.description}</p>

                <button
                  onMouseDown={(e) => e.preventDefault()}
                  type="button"
                  onClick={this.askQuestion}
                  className="text-button w-inline-block pointer"
                >
                  Stel zelf een vraag
                  <div className="text-button-line primary-color" />
                </button>

                <p className="text-button label inline horizontal-spacing">Je vraag wordt anoniem geplaatst</p>
              </div>
            </div>
          </div>
        </header>
      );
    }
    if (this.props.type === 'coaching') {
      return (
        <header className="header header-padding">
          <div className="container">
            <div className="layout">
              <div className="full">
                <h1 className="page-title">{page.title}</h1>

                <p className="description">{page.description}</p>

                <a
                  type="button"
                  href={page.headerURL}
                  target="_blank"
                  className="text-button w-inline-block pointer"
                  rel="noopener noreferrer"
                >
                  Vraag een sessie aan
                  <div className="text-button-line primary-color" />
                </a>

                <p className="text-button label inline horizontal-spacing">
                  Je ontvangt binnen 2 werkdagen een reactie
                </p>
              </div>
            </div>
          </div>
        </header>
      );
    }

    return null;
  }
}

export default Header;
