import React from 'react';

class Tab extends React.PureComponent {
  buttonRefs = {};

  clicked = false;

  sanitizeLabel = (label) => (label || '').toLowerCase().replace(' ', '');

  sanitizeHash = (hash) => (hash || '').toLowerCase().replace('#', '');

  setButtonRef = (component, label) => {
    if (component && label) {
      const cleanLabel = this.sanitizeLabel(label);

      this.buttonRefs[cleanLabel] = component;

      if (window.location.hash) {
        const cleanHash = this.sanitizeHash(window.location.hash);
        if (cleanHash === cleanLabel && !this.clicked) {
          this.clicked = true;
          setTimeout(() => {
            component.click();
          }, 1000);
        }
      }
    }
  };

  setHash = (label) => {
    const cleanLabel = this.sanitizeLabel(label);
    const cleanHash = this.sanitizeHash(window.location.hash);
    if (cleanHash !== cleanLabel) {
      // eslint-disable-next-line prefer-template
      window.location.replace(window.location.pathname + '#' + cleanLabel);
    }
  };

  selectLabel = (label) => {
    const labels = this.props.labels || [];

    this.setHash(label);

    for (let i = labels.length - 1; i >= 0; i -= 1) {
      const cleanLabel = this.sanitizeLabel(label);
      if (cleanLabel in this.buttonRefs) {
        this.buttonRefs[cleanLabel].click();
      }
    }
  };

  render() {
    const labels = this.props.labels || [];
    const children = this.props.children || [];

    return (
      <section className="section no-padding">
        <div className="container">
          <div className="layout">
            <div className="full">
              <h2>{this.props.title}</h2>
            </div>
          </div>

          <div data-duration-in="300" data-duration-out="100" className="w-tabs">
            <div className="full w-tab-menu">
              {labels.map((label, index) => (
                <button
                  className="text-button first-button tab w-inline-block w-tab-link w--current pointer"
                  data-w-tab={`Tab ${index}`}
                  key={`tab-menu-${index}`}
                  onClick={() => this.setHash(label)}
                  onMouseDown={(e) => e.preventDefault()}
                  ref={(component) => this.setButtonRef(component, label)}
                  type="button"
                >
                  {label}
                </button>
              ))}
            </div>

            <div className="tabs w-tab-content">
              {children
                .filter((c) => !!c)
                .map((child, index) => (
                  <div
                    key={`tab-content-${index}`}
                    data-w-tab={`Tab ${index}`}
                    className={`w-tab-pane ${index === 0 ? 'w--tab-active' : ''}`}
                  >
                    {child}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Tab;
