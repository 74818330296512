import React from 'react';

import MainLayout from '../components/MainLayout';
import KeepInTouch from '../sections/KeepInTouch';
import MoreAbout from '../sections/MoreAbout';
import ThemeList from '../sections/ThemeList';

import api from '../helpers/api';

class HomePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
      themes: [],
    };
  }

  componentDidMount() {
    this.getPage();
    this.getThemes();
  }

  getPage = () => {
    api.getHomePage().then((page) => {
      this.setState({ page });
    });
  };

  getThemes = () => {
    api.getThemes().then((themes) => {
      this.setState({ themes });
    });
  };

  render() {
    const page = this.state.page || {};
    const themes = this.state.themes || [];

    return (
      <MainLayout page={page} headerType="home" history={this.props.history}>
        <ThemeList themes={themes} />

        <MoreAbout gray />

        <KeepInTouch />
      </MainLayout>
    );
  }
}

export default HomePage;
