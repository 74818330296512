import React from 'react';
import { Link } from 'react-router-dom';
import isUrlExternal from 'is-url-external';

export default function Url({ children, url, ...props }) {
  return isUrlExternal(url) ? (
    <a href={url} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  ) : (
    <Link to={url} {...props}>
      {children}
    </Link>
  );
}
