import React from 'react';

import MainLayout from '../components/MainLayout';
import Rate from '../components/Rate';
import SidebarButton from '../components/SidebarButton';
import Theme from '../components/Theme';
import KeepInTouch from '../sections/KeepInTouch';
import PodcastList from '../sections/PodcastList';
import QuestionList from '../sections/QuestionList';
import Tab from '../sections/Tab';
import ToolList from '../sections/ToolList';
import NextSteps from '../sections/NextSteps';

import api from '../helpers/api';

class ThemePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      podcasts: [],
      questions: [],
      questionsPage: {},
      tabLabels: [],
      theme: {},
      themePage: {},
      tools: [],
      nextSteps: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.match.params.themeId !== nextProps.match.params.themeId &&
      this.props.match.params.themeId &&
      nextProps.match.params.themeId
    ) {
      // Force the router to refresh the whole page.
      this.props.history.replace('/empty');
      setTimeout(() => {
        this.props.history.replace(`/thema/${nextProps.match.params.themeId}`);
      }, 0);
    }
  }

  componentDidMount() {
    this.onPageLoad(this.props.match.params.themeId);
  }

  onPageLoad(themeId) {
    this.getQuestionsPage();
    this.getThemePage();
    this.getTheme(themeId);
    this.getThemeQuestions(themeId);
    this.getThemeTools(themeId);
    this.getThemePodcasts(themeId);
    this.getThemeNextSteps(themeId);
  }

  getQuestionsPage = () => {
    api.getQuestionsPage().then((questionsPage) => {
      this.setState({ questionsPage });
    });
  };

  getThemePage = () => {
    api.getThemePage().then((themePage) => {
      this.setState({ themePage });
    });
  };

  getTheme = (themeId) => {
    api.getTheme(themeId).then((theme) => {
      if (theme.hideQuestions) {
        this.setState({ tabLabels: ['Start', 'Podcasts', 'Oefeningen', 'Afronding'] });
      } else {
        this.setState({ tabLabels: ['Start', 'Podcasts', 'Oefeningen', 'Vragen', 'Afronding'] });
      }

      this.setState({ theme });
    });
  };

  getThemeQuestions = (themeId) => {
    api.getThemeQuestions(themeId).then((questions) => {
      this.setState({ questions });
    });
  };

  getThemeTools = (themeId) => {
    api.getThemeTools(themeId).then((tools) => {
      this.setState({ tools });
    });
  };

  getThemePodcasts = (themeId) => {
    api.getThemePodcasts(themeId).then((podcasts) => {
      this.setState({ podcasts });
    });
  };

  getThemeNextSteps = (themeId) => {
    api.getThemeNextSteps(themeId).then((nextSteps) => {
      this.setState({ nextSteps });
    });
  };

  setTabRef = (component) => {
    this.tabRef = component;
  };

  selectSecondLabel = () => this.tabRef && this.tabRef.selectLabel(this.state.tabLabels[1]);

  selectThirdLabel = () => this.tabRef && this.tabRef.selectLabel(this.state.tabLabels[2]);

  selectFourthLabel = () => this.tabRef && this.tabRef.selectLabel(this.state.tabLabels[3]);

  selectFifthLabel = () => this.tabRef && this.tabRef.selectLabel(this.state.tabLabels[4]);

  openHomePage = () => this.props.history.push('/');

  handleRate = () => {
    const theme = this.state.theme || {};

    api.addRatedPodcasts(theme.id);
  };

  render() {
    const theme = this.state.theme || {};
    const tabLabels = this.state.tabLabels || [];

    const podcastsRated = api.arePodcastsRated(theme.id);

    return (
      <MainLayout page={this.state.themePage} headerType="collection" history={this.props.history}>
        <Tab title={theme.title} labels={tabLabels} ref={this.setTabRef}>
          <Theme theme={theme} tools={this.state.tools} questions={this.state.questions}>
            <SidebarButton
              className="width-auto"
              onClick={this.selectSecondLabel}
              text="Ga door naar podcasts"
              primary
            />
          </Theme>

          <PodcastList theme={theme} podcasts={this.state.podcasts} noSection hideThemes>
            {theme.disableRating || !this.state.podcasts || !this.state.podcasts.length ? null : (
              <div className="column-2">
                <Rate
                  onRate={this.handleRate}
                  formValues={{ themeId: theme.id }}
                  rated={podcastsRated}
                  title="Beoordeel de podcasts"
                  description="Hoe zou je de podcasts beoordelen die je zojuist hebt geluisterd? Heeft het je geholpen? Laat het andere gebruikers weten."
                />
              </div>
            )}

            <div className="column-2">
              <SidebarButton
                className="width-auto"
                onClick={this.selectThirdLabel}
                text="Ga door naar oefeningen"
                primary
              />
            </div>
          </PodcastList>

          <ToolList tools={this.state.tools} className="padding-xsmall" noSection hideThemes>
            <div className="column-3">
              <SidebarButton
                className="width-auto"
                onClick={this.selectFourthLabel}
                text={theme.hideQuestions ? 'Ga door naar afronding' : 'Ga door naar vragen'}
                primary
              />
            </div>
          </ToolList>

          {!theme.hideQuestions && (
            <QuestionList
              className="padding-xsmall"
              history={this.props.history}
              page={this.state.questionsPage}
              questions={this.state.questions}
              enableAskQuestion
              hideContent
              hideThemes
              noSection
            >
              <div className="column-2">
                <SidebarButton
                  className="width-auto"
                  onClick={this.selectFifthLabel}
                  text="Ga door naar afronding"
                  primary
                />
              </div>
            </QuestionList>
          )}

          {/* <Coach coach={this.state.coach}>
            <SidebarButton className="width-auto" onClick={this.openHomePage} text="Sluit dit thema af" primary />
          </Coach> */}
        </Tab>

        {window.location.hash === '#afronding' && (
          <NextSteps theme={theme} steps={this.state.nextSteps} history={this.props.history} />
        )}
        <KeepInTouch gray />
      </MainLayout>
    );
  }
}

export default ThemePage;
