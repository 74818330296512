import React from 'react';

import ExternHTML from './ExternHTML';
import Url from './Url';

export default function NextStep(props) {
  return (
    <div className="column-2">
      <div className="card radius white-background">
        {props.logo && (
          <img className="card-image" alt="" src={props.logo} style={{ width: '60%', marginBottom: 32 }} />
        )}
        {props.title && <h3 className="card-title">{props.title}</h3>}
        {props.description && <p>{props.description}</p>}
        {props.html && <ExternHTML html={props.html} />}
        {props.link && (
          <Url url={props.link} className="text-button w-inline-props" style={{ display: 'inline-block' }}>
            {props.linkName}
            <div className="text-button-line primary-color" />
          </Url>
        )}
      </div>
    </div>
  );
}
