import React from 'react';

import ExternHTML from '../components/ExternHTML';
import Sidebar from '../components/Sidebar';
import SidebarButton from '../components/SidebarButton';
import SidebarSteps from '../components/SidebarSteps';

class ToolStep extends React.PureComponent {
  state = {
    previousText: '',
    previousLink: '',
    nextText: '',
    nextLink: '',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.onPageLoad(nextProps);
  }

  componentDidMount() {
    this.onPageLoad(this.props);
  }

  onPageLoad = (props) => {
    const tool = props.tool || {};
    const currentStep = props.step || {};
    const steps = tool.steps || [];

    const previousStep = steps.find((step) => step.index === currentStep.index - 1);
    const previousText = previousStep ? 'Vorige stap' : 'Terug naar overzicht';
    const previousLink = previousStep ? `/oefening/${tool.id}/stap/${previousStep.id}` : `/oefening/${tool.id}`;

    const nextStep = steps.find((step) => step.index === currentStep.index + 1);
    const nextText = nextStep ? 'Volgende stap' : 'Afronden';
    const nextLink = nextStep ? `/oefening/${tool.id}/stap/${nextStep.id}` : `/oefening/${tool.id}/beoordeling`;

    this.setState({
      previousText,
      previousLink,
      nextText,
      nextLink,
    });
  };

  previousTool = () => {
    this.props.history.push(this.state.previousLink);
  };

  nextTool = () => {
    this.props.history.push(this.state.nextLink);
  };

  render() {
    const tool = this.props.tool || {};
    const currentStep = this.props.step || {};
    const steps = tool.steps || [];

    return (
      <section className="section">
        <div className="container">
          <div className="layout">
            <div className="content">
              <button
                onMouseDown={(e) => e.preventDefault()}
                type="button"
                onClick={this.previousTool}
                className="text-button w-inline-block pointer"
              >
                {this.state.previousText}
                <div className="text-button-line" />
              </button>

              <h2 className="page-title">{currentStep.title}</h2>

              <ExternHTML html={currentStep.contentHTML} />
            </div>

            <Sidebar>
              <SidebarSteps steps={steps} currentId={currentStep.id} />

              <SidebarButton onClick={this.nextTool} text={this.state.nextText} primary />
            </Sidebar>
          </div>
        </div>
      </section>
    );
  }
}

export default ToolStep;
