import React from 'react';

class FormDone extends React.PureComponent {
  render() {
    if (!this.props.visible) {
      return null;
    }

    const text = this.props.text || 'Het formulier is opgestuurd';
    const className = this.props.className || '';

    return <div className={`form-done ${className}`}>✅ {text}</div>;
  }
}

export default FormDone;
