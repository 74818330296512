import * as types from '../actions/actionTypes';

const initialState = {
  themes: [],
};

export default function themes(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVED_THEMES:
      return {
        ...state,
        themes: action.themes,
      };
    default:
      return state;
  }
}
