import React from 'react';

import MainLayout from '../components/MainLayout';
import AskQuestion from '../sections/AskQuestion';

import api from '../helpers/api';

class AskQuestionPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
      themes: [],
    };
  }

  componentDidMount() {
    // Redirect visitor that is not logged in to the home page.
    if (!api.isLoggedIn()) {
      this.props.history.push('/');

      return;
    }

    this.getPage();
    this.getThemes();
  }

  getPage = () => {
    api.getAskQuestionPage().then((page) => {
      this.setState({ page });
    });
  };

  getThemes = () => {
    api.getThemes().then((themes) => {
      const enabledThemes = themes.filter((t) => !t.hideQuestions).filter(({ id }) => id !== 'placeholder');
      this.setState({ themes: enabledThemes });
    });
  };

  render() {
    const page = this.state.page || {};
    const themes = this.state.themes || [];

    return (
      <MainLayout page={page} headerType="back" history={this.props.history}>
        <AskQuestion themes={themes} history={this.props.history} />
      </MainLayout>
    );
  }
}

export default AskQuestionPage;
