import React from 'react';

import MainLayout from '../components/MainLayout';
import ToolList from '../sections/ToolList';
import KeepInTouch from '../sections/KeepInTouch';

import api from '../helpers/api';

class ToolsPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
      tools: [],
    };
  }

  componentDidMount() {
    this.getPage();
    this.getTools();
  }

  getPage = () => {
    api.getToolsPage().then((page) => {
      this.setState({ page });
    });
  };

  getTools = () => {
    api.getTools().then((tools) => {
      this.setState({ tools });
    });
  };

  render() {
    const page = this.state.page || {};
    const tools = this.state.tools || [];
    const sidebar = page.sidebar || [];

    return (
      <MainLayout page={page} headerType="collection" history={this.props.history}>
        <ToolList history={this.props.history} tools={tools} sidebar={sidebar} page={page} className="padding-xsmall" />

        <KeepInTouch gray />
      </MainLayout>
    );
  }
}

export default ToolsPage;
