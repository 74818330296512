import React from 'react';

import MainLayout from '../components/MainLayout';
import Question from '../sections/Question';

import api from '../helpers/api';

class QuestionPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
      questionId: this.props.match.params.questionId,
      question: {},
      answers: [],
      followers: [],
      theme: {},
    };
  }

  componentDidMount() {
    this.getPage();
    this.getQuestion();
    this.getQuestionAnswers();
    this.getQuestionFollowers();
  }

  getPage = () => {
    api.getQuestionPage().then((page) => {
      this.setState({ page });
    });
  };

  getQuestion = () => {
    api.getQuestion(this.state.questionId).then((question) => {
      this.getTheme(question.themeId);

      this.setState({ question });
    });
  };

  getQuestionAnswers = () => {
    api.getQuestionAnswers(this.state.questionId).then((answers) => {
      this.setState({ answers });
    });
  };

  getQuestionFollowers = () => {
    api.getQuestionFollowers(this.state.questionId).then((followers) => {
      this.setState({ followers });
    });
  };

  getTheme = (themeId) => {
    api.getTheme(themeId).then((theme) => {
      this.setState({ theme });
    });
  };

  render() {
    const page = this.state.page || {};
    const question = this.state.question || {};
    const answers = this.state.answers || [];
    const followers = this.state.followers || [];
    const theme = this.state.theme || {};
    const sidebar = page.sidebar || {};

    return (
      <MainLayout page={page} headerType="empty" history={this.props.history}>
        <Question
          history={this.props.history}
          followers={followers}
          question={question}
          theme={theme}
          answers={answers}
          sidebar={sidebar}
        />
      </MainLayout>
    );
  }
}

export default QuestionPage;
