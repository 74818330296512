import React from 'react';
import { Link } from 'react-router-dom';
import SidebarButton from '../components/SidebarButton';

class ArticleList extends React.PureComponent {
  render() {
    const className = this.props.className || '';
    const backgroundClass = this.props.gray ? 'gray-background' : 'white-background';

    if (this.props.noSection) {
      return <div className={`${backgroundClass} ${className}`}>{this.renderContent()}</div>;
    }

    return (
      <section id="theme-list" className={`section ${backgroundClass} ${className}`}>
        {this.renderContent()}
      </section>
    );
  }

  renderContent() {
    let themes = this.props.themes || [];

    if (this.props.highlighted && themes.length > 2) {
      themes = themes.slice(0, 2);
    }

    const containerClass = this.props.noSection ? '' : 'container';

    return (
      <div className={containerClass}>
        {this.props.highlighted ? (
          <div className="layout">
            <div className="full">
              <div className="flex-parent">
                <div className="flex-left">
                  <h3 className="section-title">Laatste thema&#x27;s</h3>
                </div>

                <div className="flex-right">
                  <Link to="/thema's" className="text-button w-inline-block">
                    Zie alle thema&#x27;s
                    <div className="text-button-line" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="layout">
          {themes.map((theme) => (
            <Link className="column-2" key={`theme-${theme.id}`} to={theme.url ?? `/thema/${theme.id}`}>
              <div className="thumbnail-image radius" style={{ backgroundImage: `url('${theme.imageURL}')` }} />

              {theme.title && <h3>{theme.title}</h3>}
              {theme.description && <p>{theme.description}</p>}
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default ArticleList;
