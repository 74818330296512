import React from 'react';

import FormSubmit from '../components/FormSubmit';
import FormDone from '../components/FormDone';
import FormFail from '../components/FormFail';

import api from '../helpers/api';

class AskQuestion extends React.PureComponent {
  state = {
    submitting: false,
    formValues: {
      themeId: null,
      title: null,
      message: null,
    },
  };

  themeSelected = (themeId) => {
    const formValues = {
      ...this.state.formValues,
      themeId,
    };

    this.setState({ formValues });
  };

  handleChange = (event) => {
    const formValues = { ...this.state.formValues };
    formValues[event.target.name] = event.target.value;

    this.setState({ formValues });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { formValues } = this.state;

    this.setState({ submitting: true });

    api.addQuestion(formValues).then((result) => {
      this.setState({ submitting: false });

      if (result && result.questionResult && result.questionResult.questionId) {
        this.props.history.push(`/vraag/${result.questionResult.questionId}`);
      }
    });
  };

  isFormValid = () => {
    const { title, themeId } = this.state.formValues;

    return title && title.length > 0 && themeId && themeId.length > 0;
  };

  render() {
    const themes = this.props.themes || [];
    const submitDisabled = !this.isFormValid() || this.state.submitting;

    return (
      <section className="section">
        <div className="container">
          <div className="layout">
            <div className="full">
              <h2>Stel een vraag</h2>

              <div className="w-form">
                <form onSubmit={this.handleSubmit} className="form w-clearfix">
                  <label className="field-label">Waar heeft je vraag mee te maken?</label>

                  <div className="radio-button-parent">
                    {themes.map((theme) => (
                      <div key={`theme-radio-${theme.id}`} className="radio-button w-radio">
                        <input
                          type="radio"
                          id={`theme-${theme.id}`}
                          name="theme"
                          value={theme.id}
                          data-name="theme"
                          className="radio-button-button w-radio-input"
                          onChange={() => {
                            this.themeSelected(theme.id);
                          }}
                          required
                        />

                        <label htmlFor={`theme-${theme.id}`} className="w-form-label">
                          {theme.title}
                        </label>
                      </div>
                    ))}
                  </div>

                  <label htmlFor="title" className="field-label">
                    Wat is je vraag?
                  </label>

                  <textarea
                    onChange={this.handleChange}
                    id="title"
                    name="title"
                    type="text"
                    placeholder="Stel hier je vraag in één of twee zinnen"
                    maxLength="5000"
                    data-name="title"
                    className="text-field radius smaller-form w-input"
                    required
                  />

                  <label htmlFor="message" className="field-label">
                    Toelichting op je vraag <span className="optional">(optioneel)</span>
                  </label>

                  <textarea
                    onChange={this.handleChange}
                    id="message"
                    name="message"
                    placeholder="Probeer je vraag uit te breiden met wat meer context"
                    maxLength="5000"
                    data-name="message"
                    className="text-field radius bigger-form w-input"
                  />

                  <div className="flex-child" />

                  <FormSubmit
                    submitting={this.state.submitting}
                    disabled={submitDisabled}
                    className="large radius primary-color"
                    text="Plaats je vraag"
                  />
                </form>

                <FormDone text="Je antwoord is verstuurd" />

                <FormFail text="Er is iets fout gegaan tijdens het versturen van je antwoord" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AskQuestion;
