import React from 'react';
import { Link } from 'react-router-dom';

import ExternHTML from '../components/ExternHTML';
import Sidebar from '../components/Sidebar';
import SidebarBlock from '../components/SidebarBlock';
import SidebarButton from '../components/SidebarButton';
import SidebarSteps from '../components/SidebarSteps';

class ToolOverview extends React.PureComponent {
  startTool = () => {
    const tool = this.props.tool || {};
    const steps = tool.steps || [];

    const firstStep = steps.find((step) => step.index === 0) || {};

    this.props.history.push(`/oefening/${tool.id}/stap/${firstStep.id}`);
  };

  render() {
    const tool = this.props.tool || {};
    const theme = this.props.theme || {};
    const steps = tool.steps || [];

    return (
      <section className="section">
        <div className="container">
          <div className="layout">
            <div className="content">
              <ExternHTML html={tool.contentHTML} />
            </div>

            <Sidebar showShare>
              <SidebarSteps atBegin steps={steps} />

              <SidebarButton onClick={this.startTool} text="Start met de oefening" primary />

              {tool.duration && (
                <SidebarBlock label="Duur">
                  <div className="sidebar-stats-parent">
                    <div className="sidebar-stats primary-color-text">{tool.duration}</div>
                  </div>
                </SidebarBlock>
              )}

              <SidebarBlock label="Intensiteit">
                <div className="sidebar-stats-parent">
                  <img className="margin-right-small" src="/images/intensity.svg" alt="" />

                  <div className="sidebar-stats secondary-color-text">Gemiddeld</div>
                </div>
              </SidebarBlock>

              {tool.averageRating && (
                <SidebarBlock label="Beoordeling">
                  <div className="sidebar-stats-parent">
                    <img className="margin-right-small" src="/images/rating-icon.svg" alt="" />

                    <div className="sidebar-stats primary-color-text">{tool.averageRating}</div>
                  </div>
                </SidebarBlock>
              )}

              <SidebarBlock label="Thema">
                <Link to={`/thema/${theme.id}`} className="theme-button sidebar-theme radius-small w-inline-block">
                  {theme.title}
                </Link>
              </SidebarBlock>
            </Sidebar>
          </div>
        </div>
      </section>
    );
  }
}

export default ToolOverview;
