import React from 'react';

import FormDone from '../components/FormDone';
import FormFail from '../components/FormFail';
import FormSubmit from '../components/FormSubmit';
import MainLayout from '../components/MainLayout';
import ExternContent from '../sections/ExternContent';

import api from '../helpers/api';

class ContactPage extends React.PureComponent {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(window.location.search);

    this.state = {
      emailAddress: '',
      message: '',
      page: {},
      themes: [],
      subject: params.get('subject'),
      theme: params.get('theme'),
      submitResult: null,
      submitting: false,
    };
  }

  componentDidMount() {
    this.getPage();
  }

  getPage = () => {
    api.getThemes().then((themes) => {
      this.setState({ themes: themes.filter(({ id }) => id !== 'placeholder') });
    });
    api.getContactPage().then((page) => {
      this.setState({ page });
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ submitting: true });

    const subjects = {
      general: 'Algemene vraag',
      theme: 'Nieuw thema voorstellen',
      reference: 'Nieuwe vervolgstap voorstellen',
    };

    api
      .formSubmit(
        this.state.emailAddress,
        [
          `Onderwerp: ${subjects[this.state.subject] ?? this.state.subject}`,
          ...(this.state.subject === 'reference'
            ? [`Voor welk thema? ${this.state.themes.find(({ id }) => this.state.theme === id)?.title}`]
            : []),
          this.state.message,
        ].join('\n\n'),
      )
      .then((result) => {
        this.setState({
          emailAddress: '',
          message: '',
          submitResult: result,
          submitting: false,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn(error);

        this.setState({
          submitResult: false,
          submitting: false,
        });
      });
  };

  onInputchange = (event) => this.setState({ [event.target.name]: event.target.value });

  render() {
    const page = this.state.page || {};

    return (
      <MainLayout page={page} headerType="standard" history={this.props.history}>
        <ExternContent html={page.contentHTML} sidebar={page.sidebar}>
          <form className="form" onSubmit={this.handleSubmit}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flexBasis: 0, flexGrow: 1, flexShrink: 1 }}>
                <label htmlFor="message" className="field-label">
                  Waar gaat het over?
                </label>
                <select
                  name="subject"
                  onChange={this.onInputchange}
                  defaultValue={this.state.subject ?? 'placeholder'}
                  required
                  className="text-field radius w-input"
                >
                  <option value="placeholder" disabled>
                    Selecteer hier je onderwerp
                  </option>
                  <option value="general">Algemene vraag</option>
                  <option value="theme">Nieuw thema voorstellen</option>
                  <option value="reference">Nieuwe vervolgstap voorstellen</option>
                </select>
              </div>
              {this.state.subject === 'reference' && (
                <div style={{ flexBasis: 0, flexGrow: 1, flexShrink: 1, marginLeft: 16 }}>
                  <label htmlFor="message" className="field-label">
                    Voor welk thema?
                  </label>
                  <select
                    name="theme"
                    onChange={this.onInputchange}
                    defaultValue={this.state.theme ?? 'placeholder'}
                    required
                    className="text-field radius w-input"
                  >
                    <option value="placeholder" disabled>
                      Selecteer hier je thema
                    </option>
                    {this.state.themes.map((theme) => (
                      <option key={theme.themeId} value={theme.themeId}>
                        {theme.title}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <label htmlFor="message" className="field-label">
              Wat wil je met ons delen?
            </label>
            <textarea
              onChange={this.onInputchange}
              value={this.state.message}
              type="email"
              name="message"
              placeholder="Vul hier je bericht in"
              className="text-field radius bigger-form w-input"
              required
            />
            <label htmlFor="emailAddress" className="field-label">
              Wat is je mailadres?
            </label>
            <input
              onChange={this.onInputchange}
              value={this.state.emailAddress}
              type="email"
              name="emailAddress"
              placeholder="Vul hier je mailadres in"
              className="text-field radius form w-input"
              required
            />
            <FormSubmit
              submitting={this.state.submitting}
              disabled={this.state.submitting}
              className="large radius primary-color"
              text="Verstuur bericht"
            />
          </form>

          <FormDone visible={this.state.submitResult === true}>Je bericht is verstuurd</FormDone>

          <FormFail visible={this.state.submitResult === false}>
            Er is iets fout gegaan tijdens het versturen van je bericht
          </FormFail>
        </ExternContent>
      </MainLayout>
    );
  }
}

export default ContactPage;
