import React from 'react';

import FormSubmit from '../components/FormSubmit';
import FormDone from '../components/FormDone';
import FormFail from '../components/FormFail';

import api from '../helpers/api';

class WriteAnswer extends React.PureComponent {
  state = {
    submitting: false,
    formValues: {
      title: null,
      message: null,
    },
  };

  handleChange = (event) => {
    const formValues = { ...this.state.formValues };
    formValues[event.target.name] = event.target.value;

    this.setState({ formValues });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const question = this.props.question || {};
    const { formValues } = this.state;
    formValues.questionId = question.id;

    this.setState({ submitting: true });

    api.addAnswer(formValues).then((result) => {
      this.setState({ submitting: false });

      if (result) {
        this.props.history.push(`/vraag/${question.id}`);
      }
    });
  };

  isFormValid = () => this.state.formValues.title && this.state.formValues.title.length;

  render() {
    const submitDisabled = !this.isFormValid() || this.state.submitting;

    return (
      <section className="section no-padding">
        <div className="container">
          <div className="content">
            <form className="form" onSubmit={this.handleSubmit}>
              <h2 className="heading-2">Schrijf een antwoord</h2>

              <label htmlFor="title">Je antwoord</label>

              <input
                type="text"
                className="text-field w-input"
                maxLength="256"
                name="title"
                data-name="title"
                placeholder="Schrijf hier je advies in één of twee zinnen"
                id="title"
                onChange={this.handleChange}
                required
              />

              <label htmlFor="message">
                Toelichting op je antwoord <span className="light">(optioneel)</span>
              </label>

              <textarea
                id="message"
                name="message"
                placeholder="Probeer hier verder toe te lichten wat je advies is"
                maxLength="5000"
                className="text-field area w-input"
                onChange={this.handleChange}
              />

              <FormSubmit
                submitting={this.state.submitting}
                disabled={submitDisabled}
                className="large radius primary-color"
                text="Plaats je antwoord"
              />
            </form>

            <FormDone text="Je antwoord is verstuurd" />

            <FormFail text="Er is iets fout gegaan tijdens het versturen van je antwoord" />
          </div>
        </div>
      </section>
    );
  }
}

export default WriteAnswer;
