import React from 'react';

class SidebarBlock extends React.PureComponent {
  render() {
    const children = this.props.children || [];
    const block = this.props.block || {};
    const label = this.props.label || block.label || '';
    const text = this.props.text || block.text || '';
    const paragraph = this.props.paragraph || block.paragraph || '';

    return (
      <div className="sidebar-element radius">
        {label ? <div className="sidebar-label">{label}</div> : null}

        {text ? <div className="sidebar-stats primary-color-text">{text}</div> : null}

        {paragraph ? <p className="sidebar-paragraph">{paragraph}</p> : null}

        {children || null}
      </div>
    );
  }
}

export default SidebarBlock;
