import React from 'react';

import MainLayout from '../components/MainLayout';
import ToolStep from '../sections/ToolStep';

import api from '../helpers/api';

class ToolPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
      tool: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.match.params.toolId !== nextProps.match.params.toolId ||
      this.props.match.params.stepId !== nextProps.match.params.stepId
    ) {
      this.onPageLoad(nextProps.match.params.toolId, nextProps.match.params.stepId);
    }
  }

  componentDidMount() {
    this.onPageLoad(this.props.match.params.toolId, this.props.match.params.stepId);
  }

  onPageLoad = (toolId, stepId) => {
    this.getPage();
    this.getTool(toolId, stepId);
  };

  getPage = () => {
    api.getToolPage().then((page) => {
      this.setState({ page });
    });
  };

  getTool = (toolId, stepId) => {
    api.getTool(toolId).then((tool) => {
      const step = this.determineStep(tool, stepId);

      this.setState({
        tool,
        step,
      });
    });
  };

  determineStep = (tool, stepId) => {
    const steps = tool.steps || [];
    const step = steps.find((s) => s.id === stepId) || {};

    return step;
  };

  render() {
    const page = this.state.page || {};
    const tool = this.state.tool || {};
    const step = this.state.step || {};

    return (
      <MainLayout page={page} headerType="empty" history={this.props.history}>
        <ToolStep history={this.props.history} tool={tool} step={step} />
      </MainLayout>
    );
  }
}

export default ToolPage;
