import React from 'react';

import MainLayout from '../components/MainLayout';
import SidebarButton from '../components/SidebarButton';
import ExternContent from '../sections/ExternContent';
import KeepInTouch from '../sections/KeepInTouch';

import api from '../helpers/api';

class AboutUsPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
    };
  }

  componentDidMount() {
    this.getPage();
  }

  getPage = () => {
    api.getAboutUsPage().then((page) => {
      this.setState({ page });
    });
  };

  render() {
    const page = this.state.page || {};

    return (
      <MainLayout page={page} headerType="image" history={this.props.history}>
        <ExternContent html={page.contentHTML} sidebar={page.sidebar} />

        <div className="container" style={{ marginTop: -128 + 32 }}>
          <div className="column-2">
            <SidebarButton
              className="width-auto"
              onClick={() => this.props.history.push('/')}
              text="Ga direct aan de slag"
              primary
            />
          </div>
        </div>

        <KeepInTouch gray />
      </MainLayout>
    );
  }
}

export default AboutUsPage;
