import React from 'react';

import ExternHTML from '../components/ExternHTML';
import Rate from '../components/Rate';
import Sidebar from '../components/Sidebar';
import SidebarButton from '../components/SidebarButton';
import SidebarSteps from '../components/SidebarSteps';

import api from '../helpers/api';

class ToolRate extends React.PureComponent {
  handleRate = () => {
    const tool = this.props.tool || {};

    api.addRatedTool(tool.id);

    setTimeout(() => {
      this.props.history.push(`/oefening/${tool.id}`);
    }, 3000);
  };

  previousTool = () => {
    const tool = this.props.tool || {};
    const steps = tool.steps || [];

    const lastStep = steps.length > 0 ? steps[steps.length - 1] : {};
    const previousLink = lastStep ? `/oefening/${tool.id}/stap/${lastStep.id}` : `/oefening/${tool.id}`;

    this.props.history.push(previousLink);
  };

  finishTool = () => {
    const tool = this.props.tool || {};

    this.props.history.push(`/thema/${tool.themeId}#oefeningen`);
  };

  render() {
    const page = this.props.page || {};
    const tool = this.props.tool || {};
    const steps = tool.steps || [];

    const toolRated = api.isToolRated(tool.id);

    return (
      <section className="section">
        <div className="container">
          <div className="layout">
            <div className="content">
              <button
                onMouseDown={(e) => e.preventDefault()}
                type="button"
                onClick={this.previousTool}
                className="text-button w-inline-block pointer"
              >
                Vorige stap
                <div className="text-button-line" />
              </button>

              <h2 className="page-title">{page.title}</h2>

              <ExternHTML html={page.contentHTML} />

              {tool.disableRating ? null : (
                <Rate
                  formValues={{ toolId: tool.id }}
                  onRate={this.handleRate}
                  rated={toolRated}
                  title="Beoordeel deze oefening"
                  description="Hoe zou je de oefening beoordelen dat je zojuist hebt gelezen? Heeft het je geholpen? Laat het andere gebruikers weten."
                />
              )}
            </div>

            <Sidebar>
              <SidebarSteps atEnd steps={steps} />

              <SidebarButton onClick={this.finishTool} text="Voltooien" primary />
            </Sidebar>
          </div>
        </div>
      </section>
    );
  }
}

export default ToolRate;
