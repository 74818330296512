import React from 'react';
import { Link } from 'react-router-dom';

import SidebarButton from '../components/SidebarButton';

import api from '../helpers/api';
import webflow from '../helpers/webflow';

class PartnerModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: {},
    };
  }

  componentDidMount() {
    api.getPartnerModal().then((modal) => {
      this.setState({ modal });
    });
  }

  hidePartnerModal = () => {
    webflow.hidePartnerModal();
  };

  render() {
    return (
      <div className="page-overlay page-overlay-partner">
        <div className="page-overlay-bg" data-ix="close-modal-partner" />

        <div className="card modal radius-big">
          <img className="modal-image" src={this.state.modal.imageURL} alt="" />

          <h3 className="modal-title">{this.state.modal.title}</h3>

          <p className="modal-paragraph">{this.state.modal.description}</p>

          <SidebarButton onClick={this.hidePartnerModal} text={this.state.modal.buttonText} primary />

          <p className="small-grey-text no-margin-bottom">
            Door gebruik te maken van Flowley ga je akkoord met onze <Link to="/algemene-voorwaarden">algemene</Link> &{' '}
            <Link to="/privacy-voorwaarden">privacy</Link> voorwaarden. Hier lees je meer{' '}
            <Link to="/over-ons">over ons</Link>.
          </p>
        </div>
      </div>
    );
  }
}

export default PartnerModal;
