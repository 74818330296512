import React from 'react';

import NextStep from '../components/NextStep';
import main from '../helpers/main';

export default function NextSteps(props) {
  return (
    <>
      <section className="section no-padding">
        <div className="container">
          <p>
            Super, je hebt dit thema helemaal afgerond! We hopen dat het je heeft geholpen om weer een beetje meer
            bewust te zijn hiervan en dat je nu een aantal handvatten hebt hoe hier zelf mee om te gaan.
          </p>
          <p>
            Toch behoefte aan meer? Je bent niet de enige. Bekijk hieronder jouw persoonlijke overzicht van mogelijke
            vervolgstappen voor dit thema.
          </p>
          <div className="header-buttons">
            <button
              onMouseDown={(e) => e.preventDefault()}
              type="button"
              onClick={() => props.history.push('/')}
              className="text-button w-inline-block pointer"
            >
              Sluit dit thema af
              <div className="text-button-line primary-color" />
            </button>

            <div className="in-between-buttons">of</div>

            <button
              onMouseDown={(e) => e.preventDefault()}
              type="button"
              onClick={() => main.scrollSmoothTo(document.getElementById('next-steps'))}
              className="text-button w-inline-block pointer"
            >
              bekijk vervolgstappen
              <div className="text-button-line" />
            </button>
          </div>
        </div>
      </section>
      <section className="section gray-background" id="next-steps">
        <div className="container">
          <div className="layout">
            {props.steps.map((step, i) => (
              <NextStep
                key={i}
                logo={step.logo}
                title={step.title}
                description={step.description}
                linkName={step.linkName}
                link={step.link}
              />
            ))}
            <NextStep
              title="Wil je zelf iets toevoegen?"
              description="Misschien heeft iets jou geholpen en wil je dit graag met anderen delen, of heb je behoefte aan iets dat hier nog ontbreekt? Laat het ons direct weten, dan gaan wij het toevoegen."
              linkName="Vervolgstap voorstellen"
              link={`/contact?subject=reference&theme=${props.theme.themeId}`}
            />
          </div>
        </div>
      </section>
    </>
  );
}
