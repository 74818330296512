import React from 'react';

import Coach from '../components/Coach';
import MainLayout from '../components/MainLayout';

import api from '../helpers/api';

class Coaching extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
      coachId: this.props.match.params.coachId,
    };
  }

  componentDidMount() {
    this.getPage();
    this.getCoach();
  }

  getPage = () => {
    api.getCoachPage().then((page) => {
      this.setState({ page });
    });
  };

  getCoach = () => {
    api.getCoach(this.state.coachId).then((coach) => {
      this.setState({ coach });
    });
  };

  render() {
    const page = this.state.page || {};

    return (
      <MainLayout page={page} headerType="standard" history={this.props.history}>
        <section className="white-background">
          <div className="container">
            <div className="layout">
              <div className="content">
                <Coach coach={this.state.coach} />
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    );
  }
}

export default Coaching;
