import React from 'react';
import { Link } from 'react-router-dom';

import Sidebar from '../components/Sidebar';
import SidebarBlock from '../components/SidebarBlock';
import SidebarButton from '../components/SidebarButton';
import ExternHTML from '../components/ExternHTML';

import api from '../helpers/api';
import webflow from '../helpers/webflow';
import main from '../helpers/main';

class Question extends React.PureComponent {
  back = () => {
    this.props.history.goBack();
  };

  toggleFollow = () => {
    if (!api.isLoggedIn()) {
      webflow.showLoginModal();
      return;
    }

    const follower = this.determineFollower();
    const following = !!(follower && Object.keys(follower).length);
    const question = this.props.question || {};

    const data = {
      follower,
      following,
      questionId: question.id,
    };

    api.toggleFollower(data).then((result) => {
      if (result) {
        main.reload();
      }
    });
  };

  determineFollower = () => {
    const followers = this.props.followers || [];
    const user = api.getLocalUser();

    for (let i = 0; i < followers.length; i += 1) {
      if (followers[i].userId === user.uid) {
        return followers[i];
      }
    }

    return {};
  };

  writeAnswer = () => {
    const question = this.props.question || {};

    if (!api.isLoggedIn()) {
      webflow.showLoginModal();

      return;
    }

    this.props.history.push(`/schrijf-antwoord/${question.id}`);
  };

  render() {
    const question = this.props.question || {};
    const theme = this.props.theme || {};
    const answers = this.props.answers || [];
    const sidebar = this.props.sidebar || {};
    const follower = this.determineFollower();
    const following = !!(follower && Object.keys(follower).length);
    const followButtonText = following ? 'Stop met volgen' : 'Volg dit vraagstuk';
    const questionLoaded = question && Object.keys(question).length > 0;

    return (
      <section className="section">
        <div className="container">
          <div className="layout">
            <div className="content">
              <button
                onMouseDown={(e) => e.preventDefault()}
                type="button"
                onClick={this.back}
                className="text-button w-inline-block pointer"
              >
                Ga terug
                <div className="text-button-line" />
              </button>

              <h2 className="page-title">{question.title}</h2>

              <p className="question-paragraph">{question.message}</p>

              {answers.map((answer) => {
                const user = answer.user || {};
                const title = answer.title || '';
                const message = answer.message || '';

                return (
                  <div className="answer-card radius">
                    {user.professional ? (
                      <Link to="/expert" className="answer-user">
                        <img src="/images/verified-user.svg" className="_15-right" alt="" />
                        <span className="bold">{user.displayName}</span>&nbsp;van&nbsp;
                        <span className="bold">{user.company}</span>&nbsp;heeft gereageerd
                      </Link>
                    ) : (
                      <div className="answer-user">
                        <img src="/images/user.svg" className="_15-right" alt="" />
                        <span className="bold">Een anonieme gebruiker</span>&nbsp;heeft gereageerd
                      </div>
                    )}

                    {title ? <h3>{title}</h3> : null}

                    <ExternHTML html={message} />
                  </div>
                );
              })}
            </div>

            <Sidebar sidebar={sidebar} showShare>
              <SidebarButton onClick={this.writeAnswer} text="Schrijf een reactie" disabled={!questionLoaded} primary />

              {question.enableFollowing && (
                <SidebarButton onClick={this.toggleFollow} text={followButtonText} disabled={!questionLoaded} />
              )}

              <SidebarBlock label="Thema">
                <Link to={`/thema/${theme.id}`} className="theme-button sidebar-theme radius-small w-inline-block">
                  {theme.title}
                </Link>
              </SidebarBlock>
            </Sidebar>
          </div>
        </div>
      </section>
    );
  }
}

export default Question;
