import React from 'react';

import SidebarBlock from './SidebarBlock';
import SidebarButton from './SidebarButton';

class Sidebar extends React.PureComponent {
  componentDidMount() {
    if (this.props.showShare && window.__sharethis__ && typeof window.__sharethis__.initialize === 'function') {
      window.__sharethis__.initialize();
    }
  }

  render() {
    const sidebar = this.props.sidebar || {};
    const children = this.props.children || [];
    const blocks = this.props.blocks || sidebar.blocks || [];
    const buttons = this.props.buttons || sidebar.buttons || [];

    return (
      <div className="sidebar">
        {buttons.map((button, index) => (
          <SidebarButton key={`sidebar-button-${index}`} button={button} />
        ))}

        {children}

        {blocks.map((block, index) => (
          <SidebarBlock key={`sidebar-block-${index}`} block={block} />
        ))}

        {this.props.showShare ? (
          <SidebarBlock
            label="Deze pagina delen"
            paragraph="Ken je iemand voor wie dit wellicht ook interessant is? Twijfel niet en deel het vooral."
          >
            <div className="sharethis-inline-share-buttons" />
          </SidebarBlock>
        ) : null}
      </div>
    );
  }
}

export default Sidebar;
