import React from 'react';

import ExternHTML from './ExternHTML';
import Sidebar from './Sidebar';
import SidebarTools from './SidebarTools';

class Theme extends React.PureComponent {
  render() {
    const theme = this.props.theme || {};
    const tools = this.props.tools || [];
    const questions = this.props.questions || [];

    const sidebarBlocks = [
      {
        label: 'Vraagstukken in dit thema',
        text: `${questions.length}`,
      },
      {
        label: 'Oefeningen in dit thema',
        text: `${tools.length}`,
      },
    ];

    return [
      theme.imageURL && (
        <div key={`theme-image-${theme.id}`} className="layout">
          <div className="full">
            <div className="header-image-small radius" style={{ backgroundImage: `url('${theme.imageURL}')` }} />
          </div>
        </div>
      ),

      <div key={`theme-content-${theme.id}`} className="layout padding-xsmall">
        <div className="content">
          <ExternHTML html={theme.contentHTML} />

          {this.props.children}
        </div>

        <Sidebar blocks={sidebarBlocks}>
          <SidebarTools tools={tools} />
        </Sidebar>
      </div>,
    ];
  }
}

export default Theme;
