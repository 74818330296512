import React from 'react';

class FormSubmit extends React.PureComponent {
  render() {
    const text = this.props.text || '';
    const className = this.props.className || '';
    const value = this.props.submitting ? 'Even wachten...' : text;

    return (
      <input
        type={this.props.disabled ? 'button' : 'submit'}
        value={value}
        data-wait="Even wachten..."
        className={`button w-button ${className} ${this.props.disabled ? 'disabled' : ''}`}
        disabled={this.props.disabled}
      />
    );
  }
}

export default FormSubmit;
