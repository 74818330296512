import React from 'react';
import ReactGA from 'react-ga';

class ReferrerPage extends React.PureComponent {
  componentDidMount() {
    // ReactGA.
    const pathname = `referrer-${this.props.match.params.referrerId}`;

    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);

    this.props.history.push('/');
  }

  render() {
    return null;
  }
}

export default ReferrerPage;
