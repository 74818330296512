import React from 'react';

class ExternHTML extends React.PureComponent {
  render() {
    const html = this.props.html || '';

    return (
      <div
        className="w-richtext"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }
}

export default ExternHTML;
