import React from 'react';
import { Helmet } from 'react-helmet';
import { style, ToastContainer } from 'react-toastify';

import Nav from './Nav';
import Header from './Header';
import Footer from './Footer';
import Cookies from './Cookies';

import LoginModal from '../modals/LoginModal';
import PartnerModal from '../modals/PartnerModal';

import api from '../helpers/api';
import ChooserModal from '../modals/ChooserModal';

class MainLayout extends React.PureComponent {
  determineKey = (elements) => `root-block-${elements.length}`;

  componentDidMount() {
    // Set the primary color as background for the info toast.
    style({
      colorInfo: '#ff7b6a',
    });
  }

  render() {
    const headerType = this.props.headerType || '';
    const page = this.props.page || {};
    const history = this.props.history || {};
    const seo = page.seo || {};
    const scripts = page.scripts || [];

    const elements = [];
    elements.push(
      <Helmet key={this.determineKey(elements)}>
        <title>{seo.title || ''}</title>

        <meta name="description" content={seo.description || ''} />

        <meta name="keywords" content={seo.keywords || ''} />

        <meta name="author" content={seo.author || ''} />

        <meta name="copyright" content={seo.copyright || ''} />

        <meta name="application-name" content={seo['application-name'] || ''} />

        <meta property="og:title" content={seo.title || ''} />

        <meta property="og:type" content={seo.type || ''} />

        <meta property="og:image" content={seo.image || ''} />

        <meta property="og:url" content={seo.url || ''} />

        <meta property="og:description" content={seo.description || ''} />

        <meta name="twitter:card" content={seo.card || ''} />

        <meta name="twitter:title" content={seo.title || ''} />

        <meta name="twitter:description" content={seo.description || ''} />

        <meta name="twitter:image" content={seo.image || ''} />

        {scripts
          .filter((script) => !!script)
          .map((script, index) => (
            <script key={`script-${index}`} type="text/javascript">
              {`try {${script}} catch (error) { console.warn(error); }`}
            </script>
          ))}
      </Helmet>,
    );

    elements.push(<div key={this.determineKey(elements)} data-ix="start-scrolling-menu" className="scroll-trigger" />);

    elements.push(<LoginModal key={this.determineKey(elements)} />);

    elements.push(<PartnerModal key={this.determineKey(elements)} />);

    elements.push(<ChooserModal key={this.determineKey(elements)} />);

    elements.push(<ToastContainer key={this.determineKey(elements)} />);

    elements.push(<Nav key={this.determineKey(elements)} />);

    elements.push(
      <div className="flex-page" key={this.determineKey(elements)}>
        <div className="flex-content">
          <Header {...this.props} type={headerType} page={page} history={history} />

          <main className="main">{this.props.children}</main>
        </div>

        <div className="flex-footer">
          <Footer />
        </div>
      </div>,
    );

    if (!api.areCookiesAccepted()) {
      elements.push(<Cookies key={this.determineKey(elements)} />);
    }

    return elements;
  }
}

export default MainLayout;
