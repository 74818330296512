import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import firebase from 'firebase/compat/app';
import ReactGA from 'react-ga';
import { detect } from 'detect-browser';
import moment from 'moment';

import AboutUsPage from './pages/AboutUsPage';
import ArticlePage from './pages/ArticlePage';
import ArticlesPage from './pages/ArticlesPage';
import AskQuestionPage from './pages/AskQuestionPage';
import CoachingPage from './pages/CoachingPage';
import CoachPage from './pages/CoachPage';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import PageNotFound from './pages/PageNotFound';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import QuestionPage from './pages/QuestionPage';
import QuestionsPage from './pages/QuestionsPage';
import ReferrerPage from './pages/ReferrerPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import ThemePage from './pages/ThemePage';
import ToolOverviewPage from './pages/ToolOverviewPage';
import ToolRatePage from './pages/ToolRatePage';
import ToolsPage from './pages/ToolsPage';
import ToolStepPage from './pages/ToolStepPage';
import WriteAnswerPage from './pages/WriteAnswerPage';

import FlexNotSupported from './components/FlexNotSupported';

import api from './helpers/api';
import webflow from './helpers/webflow';

// Initialize Google Analytics.
ReactGA.initialize('UA-119551141-2', { anonymize_ip: true });

// Initialize Firebase.
const firebaseConfig = {
  apiKey: 'AIzaSyCoGWoGe9gKjOo_qsHZWcAFmRXXFGQsnWQ',
  authDomain: 'uu.flowley.nl',
  databaseURL: 'https://flowley-uu-f3377.firebaseio.com/',
  storageBucket: 'gs://flowley-uu-f3377.appspot.com/',
};
if (!firebase.apps || !firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

const reset = () => {
  // Scroll to top of page.
  window.scrollTo(0, 0);

  // Reset Webflow.
  webflow.reset();
};

const history = createHistory();
history.listen((location) => {
  // Set the Google Analytics info.
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);

  reset();
});

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      testFailed: false,
    };
  }

  componentDidMount() {
    reset();

    const flexResult = document.createElement('div').style.flex;
    const browser = detect();
    const browserFailed =
      browser.name === 'ie' &&
      (browser.version.startsWith('7.0') ||
        browser.version.startsWith('8.0') ||
        browser.version.startsWith('9.0') ||
        browser.version.startsWith('10.0'));

    if (flexResult === undefined || browserFailed) {
      this.setState({ testFailed: true });
      return;
    }

    if (window.location.host.split('.').length < 3 || window.location.host.split('.')[0] === 'students') {
      webflow.showChooserModal();
    } else if (!api.isPartnerModalShown()) {
      webflow.showPartnerModal();
      api.setPartnerModalShown();
    }
  }

  render() {
    if (this.state.testFailed) {
      return <FlexNotSupported />;
    }

    // Be aware that changing the order of the Route components can have an undesired outcome.
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/home" component={HomePage} />
          <Route path="/thema/:themeId" component={ThemePage} />
          <Route path="/vragen" component={QuestionsPage} />
          <Route path="/vraag/:questionId" component={QuestionPage} />
          <Route path="/artikelen" component={ArticlesPage} />
          <Route path="/artikel/:articleId" component={ArticlePage} />
          <Route path="/oefeningen" component={ToolsPage} />
          <Route path="/oefening/:toolId/beoordeling" component={ToolRatePage} />
          <Route path="/oefening/:toolId/stap/:stepId" component={ToolStepPage} />
          <Route path="/oefening/:toolId" component={ToolOverviewPage} />
          <Route path="/over-ons" component={AboutUsPage} />
          <Route path="/algemene-voorwaarden" component={TermsAndConditionsPage} />
          <Route path="/privacy-voorwaarden" component={PrivacyPolicyPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/stel-vraag" component={AskQuestionPage} />
          <Route path="/schrijf-antwoord/:questionId" component={WriteAnswerPage} />
          <Route path="/expert" component={CoachingPage} />
          <Route path="/de-expert/:coachId" component={CoachPage} />
          <Route path="/browser-niet-ondersteund" component={FlexNotSupported} />
          <Route path="/referrer/:referrerId" component={ReferrerPage} />
          <Route path="/empty" component={null} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
