const main = {
  reload() {
    window.location.reload();
  },

  scrollToElem(startTime, currentTime, duration, scrollEndElemTop, startScrollOffset) {
    const runtime = currentTime - startTime;
    let progress = runtime / duration;

    progress = Math.min(progress, 1);

    const ease = progress * progress * progress;

    window.scroll(0, startScrollOffset + scrollEndElemTop * ease);
    if (runtime < duration) {
      requestAnimationFrame((timestamp) => {
        const newTime = timestamp || new Date().getTime();
        this.scrollToElem(startTime, newTime, duration, scrollEndElemTop, startScrollOffset);
      });
    }
  },

  scrollSmoothTo(e) {
    if (e) {
      // if (e.scrollIntoView && 'scrollBehavior' in document.documentElement.style) {
      //   e.scrollIntoView({ block: 'start', behavior: 'smooth' });
      // } else {
      requestAnimationFrame((timestamp) => {
        const navbar = document.querySelector('.navbar');

        const stamp = timestamp || new Date().getTime();
        const duration = 600;
        const start = stamp;

        const startScrollOffset = window.pageYOffset;
        const scrollEndElemTop = e.getBoundingClientRect().top - navbar.offsetHeight + 40;

        this.scrollToElem(start, stamp, duration, scrollEndElemTop, startScrollOffset);
      });
      // }
    }
  },
};

export default main;
