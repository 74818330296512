import React from 'react';

import MainLayout from '../components/MainLayout';
import Article from '../sections/Article';

import api from '../helpers/api';

class ArticlePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
      articleId: this.props.match.params.articleId,
      article: {},
      theme: {},
      tools: [],
    };
  }

  componentDidMount() {
    this.getPage();
    this.getArticle();
  }

  getPage = () => {
    api.getArticlePage().then((page) => {
      this.setState({ page });
    });
  };

  getArticle = () => {
    api.getArticle(this.state.articleId).then((article) => {
      this.getTheme(article.themeId);
      this.getTools(article.themeId);

      this.setState({ article });
    });
  };

  getTheme = (themeId) => {
    api.getTheme(themeId).then((theme) => {
      this.setState({ theme });
    });
  };

  getTools = (themeId) => {
    api.getThemeTools(themeId).then((tools) => {
      this.setState({ tools });
    });
  };

  render() {
    const page = this.state.page || {};
    const article = this.state.article || {};
    const theme = this.state.theme || {};
    const tools = this.state.tools || [];

    return (
      <MainLayout page={page} headerType="detail" history={this.props.history} article={article}>
        <Article article={article} theme={theme} tools={tools} />
      </MainLayout>
    );
  }
}

export default ArticlePage;
