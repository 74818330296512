import React from 'react';
import { Link } from 'react-router-dom';

import Sidebar from '../components/Sidebar';
import ExternHTML from '../components/ExternHTML';
import Pill from '../components/Pill';

class ToolList extends React.PureComponent {
  openThemePage = (theme) => {
    this.props.history.push(`/thema/${theme.id}`);
  };

  render() {
    const className = this.props.className || '';
    const page = this.props.page || {};
    const sidebar = this.props.sidebar || {};
    const backgroundClass = this.props.gray ? 'gray-background' : 'white-background';
    const showPage = page && Object.keys(page).length > 0;
    const showSidebar = sidebar && Object.keys(sidebar).length > 0;

    const elements = [];

    if (this.props.noSection) {
      elements.push(
        <div key="question-list-no-section" className={`${backgroundClass} ${className}`}>
          {this.renderTools()}
        </div>,
      );
    } else {
      elements.push(
        <section key="question-list-section" className={`section ${backgroundClass} ${className}`}>
          {this.renderTools()}
        </section>,
      );
    }

    if (showPage || showSidebar) {
      elements.push(this.renderContentHTML());
    }

    return elements;
  }

  renderTools() {
    let tools = this.props.tools || [];

    if (this.props.highlighted && tools.length > 3) {
      tools = tools.slice(0, 3);
    }

    const containerClass = this.props.noSection ? '' : 'container';

    return (
      <div key="question-list-container" className={containerClass}>
        {this.props.highlighted ? (
          <div className="layout">
            <div className="full">
              <div className="flex-parent">
                <div className="flex-left">
                  <h3 className="section-title">Uitgelichte oefeningen</h3>
                </div>

                <div className="flex-right">
                  <Link to="/oefeningen" className="text-button w-inline-block">
                    Zie alle oefeningen
                    <div className="text-button-line" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="layout">
          {tools.map((tool) => {
            const theme = tool.theme || {};

            return (
              <div key={`tool-${tool.id}`} className="column-3">
                <Link to={`/oefening/${tool.id}`}>
                  <div className="oefening-image radius" style={{ backgroundImage: `url('${tool.imageURL}')` }}>
                    <div className="challenge-info">
                      <h3 className="thumbnail-title">{tool.title}</h3>

                      {this.props.hideThemes ? null : (
                        <button
                          onMouseDown={(e) => e.preventDefault()}
                          type="button"
                          onClick={() => this.openThemePage(theme)}
                          className="theme-button no-border w-inline-block pointer"
                        >
                          {theme.title}
                        </button>
                      )}
                    </div>

                    <div className="dark-overlay" />

                    <Pill text={tool.intensity} image="blue" className="left" />

                    <Pill text={tool.averageRating} image="red" />
                  </div>
                </Link>
              </div>
            );
          })}
        </div>

        {this.props.children}
      </div>
    );
  }

  renderContentHTML() {
    const page = this.props.page || {};
    const sidebar = this.props.sidebar || {};
    const showPage = page && Object.keys(page).length > 0;
    const showSidebar = sidebar && Object.keys(sidebar).length > 0;

    return (
      <section className="section no-padding">
        <div className="container">
          <div className="layout">
            {showPage ? (
              <div className="content">
                <ExternHTML html={page.contentHTML} />
              </div>
            ) : null}

            {showSidebar ? <Sidebar sidebar={sidebar} /> : null}
          </div>
        </div>
      </section>
    );
  }
}

export default ToolList;
