import React from 'react';
import { Link } from 'react-router-dom';

import api from '../helpers/api';

class Cookies extends React.PureComponent {
  state = {
    hide: false,
  };

  setCookiesAccepted = () => {
    api.setCookiesAccepted();

    this.setState({ hide: true });
  };

  setCookiesNotAccepted = () => {
    api.setCookiesNotAccepted();

    this.setState({ hide: true });
  };

  render() {
    if (this.state.hide) {
      return null;
    }

    return (
      <div className="cookies">
        <div className="container-large">
          <div className="flex-parent">
            <div className="flex-left cookies-flex-left">
              <div className="cookies-text">
                Deze website maakt gebruik van cookies. Lees onze{' '}
                <Link to="/algemene-voorwaarden" className="cookies-text link">
                  voorwaarden
                </Link>{' '}
                voor meer informatie.
              </div>
            </div>

            <div className="flex-right">
              <button
                onMouseDown={(e) => e.preventDefault()}
                type="button"
                onClick={this.setCookiesNotAccepted}
                className="cookies-text w-inline-block pointer"
              >
                Niet akkoord
              </button>
              &nbsp; &nbsp; &nbsp;
              <button
                onMouseDown={(e) => e.preventDefault()}
                type="button"
                onClick={this.setCookiesAccepted}
                className="cookies-text link w-inline-block pointer"
              >
                Akkoord
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cookies;
