import React from 'react';
import { Link } from 'react-router-dom';

import SidebarButton from '../components/SidebarButton';

import api from '../helpers/api';
import webflow from '../helpers/webflow';

class ChooserModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: {},
    };
  }

  componentDidMount() {
    api.getChooserModal().then((modal) => {
      this.setState({ modal });
    });
  }

  hideChooserModal = () => {
    webflow.hideChooserModal();
  };

  render() {
    return (
      <div className="page-overlay page-overlay-chooser">
        <div className="page-overlay-bg" data-ix="close-modal-chooser" />

        <div className="card modal radius-big">
          <img className="modal-image" src={this.state.modal.imageURL} alt="" />

          <h3 className="modal-title">{this.state.modal.title}</h3>

          <p className="modal-paragraph">{this.state.modal.description}</p>

          <p className="small-grey-text" style={{ marginBottom: '16px' }}>
            Kies hieronder jouw onderwijsinstelling:
          </p>

          <a href="https://uu.flowley.nl">
            <div style={{ margin: '16px 0px' }}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/flowley-uu-f3377.appspot.com/o/uu-logo.png?alt=media&token=dfbd536c-16d7-4029-a54a-e9b04735da7d"
                style={{ maxWidth: 200, maxHeight: 50 }}
              />
            </div>
          </a>

          <div className="separator" />
          <a href="https://fontys.flowley.nl">
            <div style={{ margin: '16px 0px' }}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/flowley-uu-f3377.appspot.com/o/Fontys%20logo.png?alt=media&token=771ea465-0029-49c5-93fd-d9cca74f58e6"
                style={{ maxWidth: 200, maxHeight: 50 }}
              />
            </div>
          </a>

          <p className="small-grey-text" style={{ marginTop: '32px' }}>
            Staat jouw onderwijsinstelling hier nog niet tussen? Klik dan op onderstaande knop.
          </p>

          <SidebarButton onClick={this.hideChooserModal} text="Ga door naar Flowley" primary />

          <p className="small-grey-text no-margin-bottom">
            Door gebruik te maken van Flowley ga je akkoord met onze <Link to="/algemene-voorwaarden">algemene</Link> &{' '}
            <Link to="/privacy-voorwaarden">privacy</Link> voorwaarden. Hier lees je meer{' '}
            <Link to="/over-ons">over ons</Link>.
          </p>
        </div>
      </div>
    );
  }
}

export default ChooserModal;
