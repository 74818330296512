import React from 'react';

class SidebarButton extends React.PureComponent {
  onClick = () => {
    if (!this.props.disabled) {
      const button = this.props.button || {};
      const url = this.props.url || button.url || null;

      if (this.props.onClick) {
        this.props.onClick();
      } else if (url) {
        window.open(url, '_blank');
      }
    }
  };

  render() {
    const button = this.props.button || {};
    const primary = this.props.primary || button.primary || false;
    const text = this.props.text || button.text || '';

    return (
      <button
        onMouseDown={(e) => e.preventDefault()}
        type="button"
        onClick={this.onClick}
        className={`sidebar-button radius w-inline-block pointer ${primary ? 'primary-color' : ''} ${
          this.props.disabled ? 'disabled' : ''
        } ${this.props.className || ''}`}
        disabled={this.props.disabled}
      >
        {text}
      </button>
    );
  }
}

export default SidebarButton;
