import React from 'react';
import { toast } from 'react-toastify';

import FormDone from './FormDone';
import FormFail from './FormFail';
import FormSubmit from './FormSubmit';

import api from '../helpers/api';

class Rate extends React.PureComponent {
  state = {
    submitting: false,
    formValues: {
      grade: null,
    },
  };

  options = [
    {
      label: '1',
      value: 1,
    },
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
    {
      label: '4',
      value: 4,
    },
    {
      label: '5',
      value: 5,
    },
  ];

  optionSelected = (option) => {
    const formValues = { ...this.state.formValues };
    formValues.grade = option.value;

    this.setState({ formValues });
  };

  isFormValid = () => !!this.state.formValues.grade;

  handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      ...this.state.formValues,
      ...(this.props.formValues || {}),
    };

    let result;
    this.setState({ submitting: true });
    try {
      result = await api.addRating(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
    this.setState({ submitting: false });

    if (result) {
      toast.info('De beoordeling is geplaatst');
      if (typeof this.props.onRate === 'function') {
        this.props.onRate();
      }
    } else {
      toast.info('De beoordeling kon niet worden geplaatst');
    }
  };

  render() {
    const title = this.props.title || '';
    const description = this.props.description || '';

    const submitDisabled = !this.isFormValid() || this.state.submitting || this.props.rated;

    return (
      <div className="w-form">
        <form onSubmit={this.handleSubmit} className="form w-clearfix" data-name="rating">
          <div className="content-card radius">
            <div className="sidebar-label">{title}</div>

            {this.props.rated ? (
              <p className="sidebar-paragraph">Je hebt al een beoordeling geplaatst.</p>
            ) : (
              <p className="sidebar-paragraph">{description}</p>
            )}

            <div className="hearts w-clearfix">
              {this.options.map((option) => (
                <button
                  key={`rate-button-${option.value}`}
                  onMouseDown={(e) => e.preventDefault()}
                  type="button"
                  onClick={() => {
                    this.optionSelected(option);
                  }}
                  className="heart pointer"
                  data-ix={`heart-${option.label}`}
                >
                  <div className="click-radio w-radio" data-ix={`heart-${option.label}`}>
                    <input
                      type="radio"
                      id="rating"
                      name="rating"
                      value={`rating-${option.label}`}
                      data-name="rating"
                      className="hide-radio-button w-radio-input"
                    />

                    <label htmlFor="rating" className="click-radio w-form-label" />
                  </div>

                  <img src="/images/heart-inactive-svg.svg" className={`heart-inactive-${option.label}`} alt="" />

                  {this.props.rated ? null : (
                    <img src="/images/heart-active-svg.svg" className={`heart-hover-${option.label}`} alt="" />
                  )}

                  {this.props.rated ? null : (
                    <img src="/images/heart-active-svg.svg" className={`heart-active-${option.label}`} alt="" />
                  )}
                </button>
              ))}

              <FormSubmit
                submitting={this.state.submitting}
                disabled={submitDisabled}
                className="text-button submit"
                text="Plaats je beoordeling"
              />
            </div>
          </div>
        </form>

        <FormDone text="Bedankt voor je beoordeling!" className="form gray-background radius" />

        <FormFail text="Oeps! Er ging iets fout tijdens het versturen." className="form error gray-background radius" />
      </div>
    );
  }
}

export default Rate;
