import $ from 'jquery';

const ix = window.Webflow ? window.Webflow.require('ix') : null;

// Disable automatic sending email by forms.
if (window && window.Webflow) {
  window.Webflow.push(() => {
    // Disable submitting form fields during development
    $('form').submit(() => false);
  });
}

const webflow = {
  showLoginModal() {
    this.showPageOverlay('login');
  },

  showPartnerModal() {
    this.showPageOverlay('partner');
  },

  showChooserModal() {
    this.showPageOverlay('chooser');
  },

  showPageOverlay(identifier) {
    const className = `.page-overlay-${identifier}`;
    const $targetPageOverlay = $(className);

    const trigger = {
      type: 'click',
      selector: className,
      stepsA: [
        {
          display: 'flex',
        },
        {
          opacity: 1,
          transition: 'opacity 100ms ease 0',
        },
      ],
      stepsB: [],
    };

    if (ix) {
      ix.run(trigger, $targetPageOverlay);
    }
  },

  hideLoginModal() {
    this.hidePageOverlay('login');
  },

  hidePartnerModal() {
    this.hidePageOverlay('partner');
  },

  hideChooserModal() {
    this.hidePageOverlay('chooser');
  },

  hidePageOverlay(identifier) {
    const className = `.page-overlay-${identifier}`;
    const $targetPageOverlay = $(className);

    const trigger = {
      type: 'click',
      selector: className,
      stepsA: [
        {
          opacity: 0,
          transition: 'opacity 100ms ease 0',
        },
        {
          display: 'none',
        },
      ],
      stepsB: [],
    };

    if (ix) {
      ix.run(trigger, $targetPageOverlay);
    }
  },

  reset() {
    return new Promise((resolve) => {
      if (window.Webflow) {
        // Set a small timeout to make sure the reset fires after the entire DOM is loaded.
        setTimeout(() => {
          window.Webflow.destroy();
          window.Webflow.ready();
          resolve(true);
        }, 1000);
      } else {
        resolve(false);
      }
    });
  },
};

export default webflow;
