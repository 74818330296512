import React from 'react';

import MainLayout from '../components/MainLayout';
import ToolOverview from '../sections/ToolOverview';

import api from '../helpers/api';

class ToolOverviewPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
      toolId: this.props.match.params.toolId,
      tool: {},
      theme: {},
    };
  }

  componentDidMount() {
    this.getPage();
    this.getTool();
  }

  getPage = () => {
    api.getToolPage().then((page) => {
      this.setState({ page });
    });
  };

  getTool = () => {
    api.getTool(this.state.toolId).then((tool) => {
      if (tool.themeId) {
        this.getTheme(tool.themeId);
      }

      this.setState({ tool });
    });
  };

  getTheme = (themeId) => {
    api.getTheme(themeId).then((theme) => {
      this.setState({ theme });
    });
  };

  render() {
    const page = this.state.page || {};
    const tool = this.state.tool || {};
    const theme = this.state.theme || {};

    return (
      <MainLayout page={page} headerType="tool-overview" history={this.props.history} theme={theme} tool={tool}>
        <ToolOverview history={this.props.history} tool={tool} theme={theme} />
      </MainLayout>
    );
  }
}

export default ToolOverviewPage;
