import React from 'react';
import { Link } from 'react-router-dom';

import FacebookButton from '../components/FacebookButton';
import GoogleButton from '../components/GoogleButton';

import api from '../helpers/api';
import webflow from '../helpers/webflow';
import main from '../helpers/main';

class LoginModal extends React.PureComponent {
  onLoginResult = (user) => {
    if (user) {
      api.login(user).then((result) => {
        if (result) {
          webflow.hideLoginModal();

          main.reload();
        }
      });
    }
  };

  onLoginError = () => {
    webflow.hideLoginModal();
  };

  render() {
    return (
      <div className="page-overlay page-overlay-login">
        <div className="page-overlay-bg" data-ix="close-modal-login" />

        <div className="card modal radius-big">
          <h3 className="modal-title">Meld je aan voor Flowley</h3>

          <p className="modal-paragraph">
            Aanmelden is niet verplicht en is alleen nodig om vragen te kunnen stellen en reacties te kunnen plaatsen op
            Flowley.
          </p>

          <GoogleButton onResult={this.onLoginResult} onError={this.onLoginError} />

          <p className="small-grey-text">
            Door gebruik te maken van Flowley ga je akkoord met onze <Link to="/algemene-voorwaarden">algemene</Link> &{' '}
            <Link to="/privacy-voorwaarden">privacy</Link> voorwaarden. Hier lees je meer{' '}
            <Link to="/over-ons">over ons</Link>.
          </p>
        </div>
      </div>
    );
  }
}

export default LoginModal;
