import React from 'react';
import { Link } from 'react-router-dom';

import api from '../helpers/api';

class Footer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      themes: [],
    };
  }

  componentDidMount() {
    this.getThemes();
  }

  getThemes = () => {
    api.getThemes().then((themes) => {
      this.setState({ themes: themes.filter(({ id }) => id !== 'placeholder') });
    });
  };

  render() {
    const themes = this.state.themes || [];

    return (
      <div className="footer primary-color">
        <div className="container">
          <div className="layout">
            <div className="column-4 column-logo">
              <Link to="/home" className="logo w-inline-block">
                <img src="/images/logo-white.svg" alt="" />
              </Link>
            </div>

            <div className="column-4 row" />

            <div className="column-4 row">
              <h4 className="footer-section white-color-text">Thema&#x27;s</h4>

              {themes.map((theme) => (
                <Link
                  key={`theme-${theme.id}`}
                  to={`/thema/${theme.id}`}
                  className="footer-link white-color-text w-inline-block"
                >
                  {theme.title}
                </Link>
              ))}
            </div>

            <div className="column-4 row">
              <h4 className="footer-section white-color-text">Informatie</h4>

              <Link to="/over-ons" className="footer-link white-color-text w-inline-block">
                Over ons
              </Link>

              <Link to="/algemene-voorwaarden" className="footer-link white-color-text w-inline-block">
                Algemene voorwaarden
              </Link>

              <Link to="/privacy-voorwaarden" className="footer-link white-color-text w-inline-block">
                Privacy voorwaarden
              </Link>

              <Link to="/contact" className="footer-link white-color-text w-inline-block">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
