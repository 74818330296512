import React from 'react';

import ExternHTML from './ExternHTML';
import Sidebar from './Sidebar';

class Coach extends React.PureComponent {
  render() {
    const coach = this.props.coach || {};

    const sidebarButtons = coach.profileURL
      ? [
          {
            primary: true,
            text: 'Kom met mij in contact',
            url: coach.profileURL,
          },
        ]
      : [];

    return (
      <div className="layout padding-small">
        <div className="content">
          {coach.imageURL && <img src={coach.imageURL} width={80} alt="" />}
          {coach.imageURL && <br />}
          {coach.imageURL && <br />}
          {coach.name && <h2>{coach.name}</h2>}
          {coach.job && <p>{coach.job}</p>}
          {coach.profileHTML && <br />}
          {coach.profileHTML && <br />}
          {coach.profileHTML && <h3>Wie ben ik?</h3>}
          {coach.profileHTML && <ExternHTML html={coach.profileHTML} />}

          {this.props.children}
        </div>

        <Sidebar buttons={sidebarButtons} />
      </div>
    );
  }
}

export default Coach;
