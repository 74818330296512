import React from 'react';
import { Link } from 'react-router-dom';

import ExternHTML from '../components/ExternHTML';
import Sidebar from '../components/Sidebar';

import api from '../helpers/api';
import webflow from '../helpers/webflow';

class QuestionList extends React.PureComponent {
  askQuestion = () => {
    if (!api.isLoggedIn()) {
      webflow.showLoginModal();

      return;
    }

    this.props.history.push('/stel-vraag');
  };

  render() {
    const className = this.props.className || '';
    const page = this.props.page || {};
    const sidebar = this.props.sidebar || {};
    const backgroundClass = this.props.gray ? 'gray-background' : 'white-background';
    const showPage = page && Object.keys(page).length > 0;
    const showSidebar = sidebar && Object.keys(sidebar).length > 0;

    const elements = [];

    if (this.props.noSection) {
      elements.push(
        <div key="question-list-no-section" className={`${backgroundClass} ${className}`}>
          {this.renderQuestions()}
        </div>,
      );
    } else {
      elements.push(
        <section key="question-list-section" className={`section ${backgroundClass} ${className}`}>
          {this.renderQuestions()}
        </section>,
      );
    }

    if ((!this.props.hideContent && showPage) || showSidebar) {
      elements.push(this.renderContentHTML());
    }

    return elements;
  }

  renderQuestions() {
    const page = this.props.page || {};
    let questions = this.props.questions || [];

    if (this.props.highlighted && questions.length > 4) {
      questions = questions.slice(0, 4);
    }

    const containerClass = this.props.noSection ? '' : 'container';

    return (
      <div key="question-list-container" className={containerClass}>
        {this.props.enableAskQuestion && (
          <div className="layout">
            <div className="full">
              <p className="description-no-margin">{page.description}</p>

              <button
                onMouseDown={(e) => e.preventDefault()}
                type="button"
                onClick={this.askQuestion}
                className="text-button w-inline-block pointer"
              >
                Stel zelf een vraag
                <div className="text-button-line primary-color" />
              </button>

              <p className="text-button label inline horizontal-spacing">Je vraag wordt anoniem geplaatst</p>
            </div>
          </div>
        )}

        {this.props.highlighted ? (
          <div className="layout">
            <div className="full">
              <div className="flex-parent">
                <div className="flex-left">
                  <h3 className="section-title">Laatste vraagstukken</h3>
                </div>

                <div className="flex-right">
                  <Link to="/vragen" className="text-button w-inline-block">
                    Zie alle vraagstukken
                    <div className="text-button-line" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className={`layout ${this.props.enableAskQuestion ? 'padding-xsmall' : ''}`}>
          {questions.map((question) => {
            const theme = question.theme || {};
            const answers = question.answers || [];

            return (
              <div key={`question-${question.id}`} className="column-2">
                <div className={`card ${this.props.gray ? 'white-background' : 'gray-background'}`}>
                  <div className="question-info">
                    <div className={`answer-count radius-small ${answers.length > 0 ? 'counting' : ''}`}>
                      <img className="margin-right-small" src="/images/question.svg" alt="" />
                      {answers.length}
                    </div>

                    {this.props.hideThemes ? null : (
                      <Link to={`/thema/${theme.id}`} className="theme-button radius w-inline-block pointer">
                        {theme.title}
                      </Link>
                    )}
                  </div>

                  <h3 className="question-title">{question.title}</h3>

                  <Link to={`/vraag/${question.id}`} className="text-button w-inline-block">
                    Bekijk vraagstuk
                    <div className="text-button-line primary-color" />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>

        {this.props.children}
      </div>
    );
  }

  renderContentHTML() {
    const page = this.props.page || {};
    const sidebar = this.props.sidebar || {};
    const showPage = page && Object.keys(page).length > 0;
    const showSidebar = sidebar && Object.keys(sidebar).length > 0;

    return (
      <section key="question-list-content-html" className="section no-padding">
        <div className="container">
          <div className="layout">
            {showPage ? (
              <div className="content">
                <ExternHTML html={page.contentHTML} />
              </div>
            ) : null}

            {showSidebar ? <Sidebar sidebar={sidebar} /> : null}
          </div>
        </div>
      </section>
    );
  }
}

export default QuestionList;
