import React from 'react';

import MainLayout from '../components/MainLayout';
import QuestionList from '../sections/QuestionList';
import KeepInTouch from '../sections/KeepInTouch';

import api from '../helpers/api';

class QuestionsPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: {},
      questions: [],
    };
  }

  componentDidMount() {
    this.getPage();
    this.getQuestions();
  }

  getPage = () => {
    api.getQuestionsPage().then((page) => {
      this.setState({ page });
    });
  };

  getQuestions = () => {
    api.getQuestions().then((questions) => {
      this.setState({ questions });
    });
  };

  render() {
    const page = this.state.page || {};
    const questions = this.state.questions || [];
    const sidebar = page.sidebar || {};

    return (
      <MainLayout page={page} headerType="questions" history={this.props.history}>
        <QuestionList questions={questions} sidebar={sidebar} page={page} className="padding-xsmall" />

        <KeepInTouch gray />
      </MainLayout>
    );
  }
}

export default QuestionsPage;
